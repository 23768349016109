import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Fulfiller, GlobalPermission } from 'app/shared/graph';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddFulfillerDialogComponent, AddFulfillerDialogResult } from './add-fulfiller-dialog.component';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { Router, RouterLink } from '@angular/router';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { FulfillerService } from './fulfiller.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'admin-fulfillers-page',
    templateUrl: './fulfillers-page.component.html',
    styleUrls: ['./fulfillers-page.component.scss'],
    imports: [PageSimpleContentComponent, MatFormField, MatIcon, MatPrefix, MatLabel, MatInput, FormsModule, MatButton, ErrorComponent, MatTable, MatColumnDef, MatHeaderCell, MatCell, RouterLink, CopyToClipboardComponent, MatHeaderRow, MatRow, MatHeaderCellDef, MatCellDef, MatHeaderRowDef, MatRowDef, NgIf]
})
export class FulfillersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly authorizationService = inject(AuthorizationService);
    private readonly fulfillerService = inject(FulfillerService);
    private readonly dialog = inject(MatDialog);
    private readonly router = inject(Router);

    @ViewChild('fulfillersTable', { static: false })
    fulfillersTable: MatTable<any>;

    fulfillers: Fulfiller[] = [];
    displayedFulfillers: Fulfiller[] = [];

    displayedColumns: string[] = [
        'id',
        'libraryPackage',
        'client',
        'url'
    ];

    filter: {
        name: string;
    } = {
        name: ''
    };

    private authorizationContext: AuthorizationContext;

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.subscribeWithGenericLoadingErrorHandling(this.fulfillerService.list(), (fulfillers) => {
            this.fulfillers = fulfillers;
            this.updateDisplayedFulfillers();
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    updateFilter() {
        this.updateDisplayedFulfillers();
    }

    private updateDisplayedFulfillers() {
        this.displayedFulfillers = this.fulfillers
            .filter(s => this.isFulfillersVisible(s))
            .sort((a, b) => a.id.localeCompare(b.id));
        this.fulfillersTable.renderRows();
    }


    private isFulfillersVisible(f: Fulfiller) {
        if (this.filter.name) {
            if (!f.id.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;
    }

    fulfillerId(i: number, fulfiller: Fulfiller) {
        return fulfiller.id;
    }

    addFulfiller() {
        this.dialog.open<AddFulfillerDialogComponent, void, AddFulfillerDialogResult>(AddFulfillerDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.fulfillerService.createFulfiller({
                id: result.fulfillerId,
                client: result.client
            }), () => {
                this.router.navigate(['/fulfillers', result.fulfillerId]);
            });
        });
    }

    canCreateFulfiller(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageFulfillers);
    }
}
