import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminComponentBase, PageSimpleContentComponent } from '../../../layout/content';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from 'app/shared/authentication';
import { AvailableRoles, DownloadPath, GlobalPermission, GlobalRole, UserRoles } from 'app/shared/graph';
import { AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult } from 'app/shared/permission/add-authorized-user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { FormsModule, NgForm } from '@angular/forms';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatChip, MatChipListbox, MatChipOption, MatChipRemove, MatChipSet } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { DatePipe, NgIf } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    templateUrl: './admin-user-page.component.html',
    styleUrls: ['./admin-user-page.component.scss'],
    imports: [PageSimpleContentComponent, ErrorComponent, CopyToClipboardComponent, MatChipSet, MatChip, MatChipRemove, MatIcon, FormsModule, MatFormField, MatLabel, MatInput, MatAutocompleteTrigger, MatAutocomplete, MatOption, MatButton, DatePipe, FromNowPipe, NgIf, MatTooltip, RouterLink, MatChipListbox, MatChipOption, MatIconButton]
})
export class AdminUserPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly dialog = inject(MatDialog);
    private readonly snackBar = inject(MatSnackBar);
    private readonly authorizedUsersService = inject(AuthorizedUsersService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    userEmail: string;
    user?: UserRoles;
    authorizationContext: AuthorizationContext;
    availableRoles: AvailableRoles;
    availableDownloads: DownloadPath[];
    downloadValue: string;
    @ViewChild('form')
    form: NgForm;

    ngOnInit() {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(this.route.params.subscribe(params => {
            this.userEmail = params.userEmail;

            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                this.authorizedUsersService.getUser(this.userEmail),
                this.authorizedUsersService.getAvailableRoles(),
                this.authorizedUsersService.getAvailableDownloadPaths()
            ], (user, availableRoles, availableDownloads) => {
                this.user = user;
                this.availableRoles = availableRoles;
                this.availableDownloads = [...availableDownloads, { path: '*' }];
            }));
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManageUser(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    // region Global role

    openAddGlobalRole(user: UserRoles) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<GlobalRole>, AddAuthorizedUserDialogData<GlobalRole>, AddAuthorizedUserDialogResult<GlobalRole>>(
            AddAuthorizedUserDialogComponent, {
                data: {
                    email: user.user.email,
                    target: {
                        availableRoles: this.availableRoles.globalRoles.filter(role => !user || !user.globalRoles.includes(role)),
                        label: user.user.email
                    }
                }
            });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRole({
                email: result.email,
                global: [result.role]
            }), () => {
                this.user?.globalRoles.push(result.role);
            });
        });
    }


    removeGlobalRole(user: UserRoles, role: GlobalRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${user.user.email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email: user.user.email,
                global: [role]
            }), () => {
                let roleIndex = user.globalRoles.indexOf(role);
                if (roleIndex !== -1) {
                    user.globalRoles.splice(roleIndex, 1);
                }
            });
        });
    }

    // endregion

    removeDownload(user: UserRoles, download: string) {
        this.confirmDialogService.confirm('Remove download', `Remove permission to download <code>${download}</code> for the user <code>${user.user.email}</code>?`, undefined, {
            yes: {
                label: 'Remove download',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email: user.user.email,
                downloads: [download]
            }), () => {
                let roleIndex = user.downloads.indexOf(download);
                if (roleIndex !== -1) {
                    user.downloads.splice(roleIndex, 1);
                }
            });
        });
    }

    addDownload(download: string) {
        this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRole({
            email: this.userEmail,
            downloads: [download]
        }), () => {
            this.user?.downloads.push(download);
        });
        this.form.resetForm();
    }

    filterDownloads(availableDownloads: DownloadPath[]) {
        if (!this.user) {
            return availableDownloads;
        }
        return availableDownloads.filter(d => !this.user?.downloads.includes(d.path));
    }

    hasAnyRole(user: UserRoles | undefined): boolean {
        if (user?.globalRoles && user.globalRoles.length > 0) {
            return true;
        }
        if (user?.subscriptionsRoles && user.subscriptionsRoles.length > 0) {
            return true;
        }
        if (user?.storesRoles && user.storesRoles.length > 0) {
            return true;
        }
        if (user?.librariesRoles && user.librariesRoles.length > 0) {
            return true;
        }
        if (user?.fulfillersRoles && user.fulfillersRoles.length > 0) {
            return true;
        }
        return false;
    }

    deleteUser(user: UserRoles | undefined) {
        let email = user?.user.email;
        if (!email) {
            return;
        }

        this.confirmDialogService.confirm('Delete User', `Delete the user <code>${email}</code> ?`, undefined, {
            yes: {
                label: 'Delete user',
                warn: true
            }
        }).subscribe(() => {
            this.snackBar.open(`Admin user ${email} has been deleted`, undefined, { duration: 5000 });
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.deleteAdminUser({
                email: email
            }), () => {
                this.router.navigate(['/admin-users']);
            });
        });
    }
}
