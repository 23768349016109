import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Auth0User } from '../graph/schema-dotnet';
import { RouterLink } from '@angular/router';
import { MatChip, MatChipListbox, MatChipOption, MatChipRemove, MatChipSet } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'admin-users-role-management',
    templateUrl: './users-roles-management.component.html',
    styleUrls: ['./users-roles-management.component.scss'],
    imports: [RouterLink, MatChipRemove, MatIcon, MatButton, MatTooltip, MatIconButton, MatChipSet, MatChip, MatChipListbox, MatChipOption]
})
export class UsersRolesManagementComponent<T extends { roles: TRole[], user: Auth0User }, TRole> {
    @Input() users: T[] = [];
    @Input() availableRoles: TRole[] = [];
    @Input() canManagePermissions: boolean;
    @Input() loading: boolean;
    @Output() roleRemoved: EventEmitter<{ email: string, role: TRole }> = new EventEmitter<{ email: string; role: TRole }>();
    @Output() addUserRole: EventEmitter<{ user: T }> = new EventEmitter<any>();
    @Output() addUser: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    canAddRole(authorizedUser: T): boolean {
        if (!this.canManagePermissions)
            return false;

        for (let availableRole of this.availableRoles) {
            if (!authorizedUser.roles.includes(availableRole))
                return true;
        }
        return false;
    }
}
