@if (data.editable) {
    <mat-dialog-content>
        <code-editor
            [ngModel]="text"
            theme="dark"
            setup="basic"
            [languages]="languages"
            [language]="data.language"
        ></code-editor>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="edit()">Edit</button>
    </mat-dialog-actions>
} @else {
    <code-editor
        [ngModel]="text"
        theme="dark"
        setup="basic"
        readonly="true"
        [languages]="languages"
        [language]="data.language"
    ></code-editor>
}
