import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

export interface ProjectDownloadingDialogData {
    downloadUrl: number
}

@Component({
    templateUrl: './project-downloading-dialog.component.html',
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class ProjectDownloadingDialogComponent {
    data = inject<ProjectDownloadingDialogData>(MAT_DIALOG_DATA);

    constructor() {
        const data = this.data;
    }
}
