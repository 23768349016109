<div class="user-list">
    @if (users.length === 0) {
        No users
    }
    @for (authorizedUser of users; track authorizedUser) {
        <div class="user">
            <img class="picture" [src]="authorizedUser.user.picture"
                 alt="Profile picture of {{authorizedUser.user.email}}" />
            <div class="email">
                <a tabindex="0"
                   [routerLink]="['/admin-users', authorizedUser.user.email]">{{ authorizedUser.user.email }}</a>
                @if (authorizedUser.user.isMfaEnabled) {
                    <span tabindex="0"
                          [matTooltip]="'Multi-factor Authentication enabled'">
                        <mat-icon>security</mat-icon>
                    </span>
                } @else {
                    <span tabindex="0" [matTooltip]="'Multi-factor Authentication disabled'">
                        <mat-icon style="color: orange">warning</mat-icon>
                    </span>
                }
            </div>
            <div class="roles">

                @if (canManagePermissions) {
                    <mat-chip-listbox>
                        @for (role of authorizedUser.roles; track role) {
                            <mat-chip-option
                                [selectable]="false"
                                [removable]="true"
                                [disabled]="loading"
                                (removed)="roleRemoved.emit({ email: authorizedUser.user.email, role: role })"
                            >
                                {{ role }}
                                <button matChipRemove [disabled]="loading" aria-label="Remove role: {{ role }}">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-option>
                        }
                    </mat-chip-listbox>
                    <button mat-icon-button [disabled]="loading" (click)="addUserRole.emit({ user: authorizedUser })">
                        <mat-icon>add</mat-icon>
                    </button>
                } @else {
                    <mat-chip-set>
                        @for (role of authorizedUser.roles; track role) {
                            <mat-chip> {{ role }}</mat-chip>
                        }
                    </mat-chip-set>
                }
            </div>
        </div>
    }
    @if (canManagePermissions) {
        <button mat-stroked-button (click)="addUser.emit()" [disabled]="loading">
            <mat-icon>add</mat-icon>
            ADD USER
        </button>
    }
</div>
