<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Project: ' + projectId + (projectVersion?.id ? ' Version: ' + projectVersion?.id : '')"
    [icon]="'save'"
>
    @if (pageStatus === "saveError" && pageError) {
        <admin-error [error]="pageError"></admin-error>
    }
    @if (project) {
        <div class="content">
            @if (project?.store) {
                <mat-tab-group>
                    <mat-tab label="Project (Current)">
                        <div class="tab-content project-tab">
                            <div class="actions">
                                <h2>Actions</h2>
                                <div class="actions-buttons">
                                    @if (project.user && canReadStoreUsers(project)) {
                                        <button
                                            [routerLink]="'/users/' + project.user.id"
                                            class="ml-4"
                                            mat-stroked-button
                                        >
                                            <mat-icon>face</mat-icon>
                                            Open user
                                        </button>
                                    }
                                    @if (project.user && canManageProject(project)) {
                                        <button
                                            [routerLink]="'/projects/' + project.id + '/edit'"
                                            [disabled]="project.isDeleted"
                                            mat-stroked-button
                                        >
                                            <mat-icon>edit</mat-icon>
                                            Edit Project
                                        </button>
                                    }
                                    @if (canManageProject(project)) {
                                        <a mat-icon-button [matMenuTriggerFor]="actionsMenu">
                                            <mat-icon>more_vert</mat-icon>
                                        </a>
                                    }
                                    <mat-menu>
                                        <mat-menu #actionsMenu="matMenu">
                                            <button
                                                mat-menu-item
                                                (click)="delete(this.project.id)"
                                                [disabled]="project.isDeleted"
                                            >
                                                <mat-icon color="warn">delete</mat-icon>
                                                Delete Project
                                            </button>
                                            @if (canDownloadProject(project)) {
                                                <button
                                                    mat-menu-item
                                                    (click)="download(projectVersion.id)"
                                                    [disabled]="project.isDeleted"
                                                >
                                                    <mat-icon>download</mat-icon>
                                                    Download Project
                                                </button>
                                            }
                                        </mat-menu>
                                    </mat-menu>
                                </div>
                            </div>
                            @if (!project.isDeleted && project.currentVersion) {
                                <img
                                    class="project-current-thumbnail"
                                    alt="Project Thumbnail"
                                    [src]="project.currentVersion.thumbnailUrl"
                                />
                            }
                            <div class="project-info">
                                <h2>Information</h2>
                                @if (project.isDeleted) {
                                    <div class="project-field">
                                        <span class="label"></span>
                                        <span class="value project-delete-notice">
                                            <mat-icon color="warn">warning</mat-icon>This project has been deleted
                                        </span>
                                    </div>
                                }
                                <div class="project-field">
                                    <span class="label">Project ID</span>
                                    <span class="value">
                                        <code adminCopyToClipboard>{{ project.id }}</code>
                                    </span>
                                </div>
                                <div class="project-field">
                                    <span class="label">Store ID</span>
                                    <span class="value">
                                        <code adminCopyToClipboard>{{ project.store.id }}</code>
                                    </span>
                                </div>
                                <div class="project-field">
                                    <span class="label">User ID</span>
                                    <span class="value">
                                        <code adminCopyToClipboard>{{ project.user.id }}</code>
                                    </span>
                                </div>
                                <div class="project-field">
                                    <span class="label">Store User ID</span>
                                    <span class="value">
                                        <code adminCopyToClipboard>{{ project.user.storeUserId }}</code>
                                    </span>
                                </div>
                                <div class="project-field">
                                    <span class="label">Created</span>
                                    <span class="value">{{ project.dateCreatedUtc | date: "medium" }}</span>
                                </div>
                                <div class="project-field">
                                    <span class="label">Versions</span>
                                    @if (project.currentVersion && projectVersion) {
                                        <span class="value">
                                            {{ getVersionPosition(project, projectVersion) }} of
                                            {{ project.versions.length }}
                                            @if (projectVersion.id === project.currentVersionId) {
                                                <b>(This is the current version)</b>
                                            }
                                            @if (projectVersion.id !== project.currentVersionId) {
                                                <b>(This is NOT the current user version)</b>
                                            }
                                        </span>
                                    }
                                    @if (!project.currentVersion) {
                                        @if (project.isDeleted) {
                                            <span class="value">
                                                <em>This project is under deletion</em>
                                            </span>
                                        } @else {
                                            <span class="value"> This project was never saved </span>
                                        }
                                    }
                                </div>
                                @if (projectVersion) {
                                    <div class="project-field">
                                        <span class="label">Last modification</span>
                                        <span class="value">{{ projectVersion.lastModifiedUtc | date: "medium" }}</span>
                                    </div>
                                }
                                <div class="project-field">
                                    <span class="label"> Properties </span>
                                    <span class="value">
                                        @for (property of project.properties; track property) {
                                            <div>
                                                <code adminCopyToClipboard>{{ property.name }}</code> -
                                                <code adminCopyToClipboard>{{ property.value }}</code>
                                            </div>
                                        }
                                    </span>
                                </div>
                                <div class="project-field">
                                    <span class="label">Title</span>
                                    <span class="value">
                                        @if (project.title) {
                                            <span>{{ project.title }}</span>
                                        }
                                        @if (!project.title) {
                                            <span><em>Untitled</em></span>
                                        }
                                    </span>
                                </div>
                                @if (project.sourceSharedProjectId) {
                                    <div class="project-field">
                                        <span class="label">Source SharedProjectId</span>
                                        <span class="value"
                                        ><code adminCopyToClipboard>{{ project.sourceSharedProjectId }}</code
                                        >(the sharedProjectId from which this project was created. This is not a
                                            projectId)</span
                                        >
                                    </div>
                                }
                                <div class="project-field">
                                    <span class="label">Module</span>
                                    <span class="value">{{ project.module }}</span>
                                </div>
                                @if (project.externalProductId) {
                                    <div class="project-field">
                                        <span class="label">Store product ID</span>
                                        <span class="value"
                                        ><code adminCopyToClipboard>{{ project.externalProductId }}</code></span
                                        >
                                    </div>
                                }
                                @if (project.product) {
                                    <div class="project-field">
                                        <span class="label">Product</span>
                                        <span class="value">
                                            <div class="project-product">
                                                <img
                                                    alt="Product Thumbnail"
                                                    class="thumbnail"
                                                    [attr.src]="project.product.thumbnail"
                                                />
                                                <a
                                                    class="title"
                                                    [routerLink]="['/stores', project.store.id, 'library', 'product']"
                                                    [queryParams]="{ id: project.product.id }"
                                                >
                                                    {{ project.product.label }}
                                                </a>
                                                <span>
                                                    <code adminCopyToClipboard>{{ project.product.id }}</code>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                }
                                @if (project.theme) {
                                    <div class="project-field">
                                        <span class="label">Theme</span>
                                        <span class="value">
                                            <div class="project-theme">
                                                <img
                                                    alt="Theme Thumbnail"
                                                    class="thumbnail"
                                                    [attr.src]="project.theme.thumbnail"
                                                />
                                                <span class="title">{{ project.theme.label }}</span>
                                                <span>
                                                    <code adminCopyToClipboard>{{ project.theme.id }}</code>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                }
                            </div>
                            @if (project.variableData) {
                                <div class="project-vdp-info">
                                    <h2>Variable Data</h2>
                                    <div class="project-field">
                                        <span class="label">Definition</span>
                                        <span class="value">
                                            <code adminCopyToClipboard>{{
                                                    project.variableData.variableDataResourceUrl
                                                }}</code>
                                        </span>
                                    </div>
                                    <div class="project-field">
                                        <span class="label">Number of rows</span>
                                        <span class="value">
                                            <span>{{ project.variableData.items }}</span>
                                        </span>
                                    </div>
                                    @if (project.variableData.dataName) {
                                        <div class="project-field">
                                            <span class="label">List name</span>
                                            <span class="value">
                                                <span>{{ project.variableData.dataName }}</span>
                                            </span>
                                        </div>
                                    }
                                    @if (project.variableData.uploadedFilename) {
                                        <div class="project-field">
                                            <span class="label">Uploaded filename</span>
                                            <span class="value">
                                                <span>{{ project.variableData.uploadedFilename }}</span>
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </mat-tab>
                    @if (project?.versions?.length > 0) {
                        <mat-tab label="Versions ({{ project.versions.length }})">
                            <div class="tab-content version-list">
                                @for (version of project.versions; track version) {
                                    <div class="version-line">
                                        <div class="version-thumbnail-container">
                                            @if (!project.isDeleted) {
                                                <img
                                                    loading="lazy"
                                                    alt="Project Version Thumbnail"
                                                    class="version-thumbnail"
                                                    [attr.src]="version.thumbnailUrl"
                                                    title="Project thumbnail"
                                                />
                                            } @else {
                                                <div class="no-preview">
                                                    <mat-icon>preview_off</mat-icon>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <ul>
                                                <li>
                                                    Id: <code>{{ version.id }}</code>
                                                </li>
                                                <li>Saved: {{ version.lastModifiedUtc | date: "medium" }}</li>
                                                @if (project.currentVersionId === version.id) {
                                                    <li>
                                                        <b>Current user version</b>
                                                    </li>
                                                }
                                                @if (projectVersionId === version.id) {
                                                    <li>
                                                        <b>Currently selected</b>
                                                    </li>
                                                }
                                                @if (projectVersionId !== version.id) {
                                                    <li>
                                                        <a
                                                            [routerLink]="[
                                                                '/projects',
                                                                project.id,
                                                                'versions',
                                                                version.id,
                                                            ]"
                                                        >Select this version</a
                                                        >
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </mat-tab>
                    }
                    @if (projectVersion) {
                        <mat-tab>
                            <ng-template matTabLabel>
                                Photos ({{ projectVersion?.photos?.length || "none" }})
                            </ng-template>
                            <div class="tab-content photo-list">
                                <div class="actions">
                                    @if (canManagePhotos(project)) {
                                        <button
                                            mat-stroked-button
                                            [disabled]="pageStatus === 'saving'"
                                            (click)="invalidateCachedPhoto(projectVersion)"
                                        >
                                            Invalidate external photos cached
                                        </button>
                                    }
                                </div>
                                @for (photo of projectVersion.photos; track photo) {
                                    <div class="photo-line">
                                        <div class="photo-column">
                                            <a [attr.href]="photo.print">
                                                @if (photo.thumbnail) {
                                                    <img
                                                        alt="Photo Thumbnail"
                                                        loading="lazy"
                                                        class="photo-thumbnail"
                                                        [attr.src]="photo.thumbnail"
                                                    />
                                                }
                                                @if (!photo.thumbnail) {
                                                    <span>Download</span>
                                                }
                                            </a>
                                        </div>
                                        @if (photo.__typename === "Photo") {
                                            <div>
                                                <ul>
                                                    <li class="photo-urn">
                                                        URN: <code adminCopyToClipboard>{{ photo.urn }}</code>
                                                        <a [routerLink]="['/photos', photo.urn]"
                                                        >
                                                            <mat-icon>info</mat-icon>
                                                        </a
                                                        >
                                                    </li>
                                                    <li>
                                                        Size: {{ photo.width }} x {{ photo.height }} ({{
                                                            getOrientation(photo.width, photo.height)
                                                        }})
                                                    </li>
                                                    <li>Uploaded: {{ photo.dateUploadedUtc | date: "medium" }}</li>
                                                    @if (photo.dateTakenUtc) {
                                                        <li>Date taken: {{ photo.dateTakenUtc | date: "medium" }}</li>
                                                    }
                                                </ul>
                                            </div>
                                        }
                                        @if (photo.__typename === "MissingPhoto") {
                                            <div>
                                                <ul>
                                                    <li>
                                                        URN: <code adminCopyToClipboard>{{ photo.urn }}</code>
                                                    </li>
                                                    <li>
                                                        <span class="p-4 red-900">This photo was not found</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </mat-tab>
                    }
                    @if (projectVersion) {
                        <mat-tab label="Xml">
                            <ng-template matTabContent>
                                <div class="tab-content xml-tab">
                                    <div class="actions">
                                        @if (pageStatus === "saving") {
                                            <mat-spinner [diameter]="24"></mat-spinner>
                                        }
                                        @if (canManageProject(project)) {
                                            <button
                                                class="save-button"
                                                mat-stroked-button
                                                (click)="saveXml(projectVersion.id, projectVersion.xml)"
                                                [disabled]="pageStatus === 'saving'">
                                                <mat-icon>save</mat-icon>
                                                Save
                                            </button>
                                        }
                                    </div>
                                    <code-editor
                                        #xmlEditor
                                        theme="dark"
                                        setup="basic"
                                        language="xml"
                                        [readonly]="!canManageProject(project)"
                                        [languages]="languages"
                                        [(ngModel)]="projectVersion.xml"
                                    ></code-editor>
                                </div>
                            </ng-template>
                        </mat-tab>
                    }
                </mat-tab-group>
            }
        </div>
    }
</admin-page-simple-content>
