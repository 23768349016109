import { Component, ElementRef, Input, inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { InteractDirective } from './interact.directive';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[adminCopyToClipboard]',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss'],
    imports: [MatIcon, InteractDirective]
})
export class CopyToClipboardComponent {
    private readonly clipboard = inject(Clipboard);
    private readonly elementRef = inject(ElementRef);
    private readonly snackBar = inject(MatSnackBar);

    @Input()
    textToCopy?: string;

    copyToClipboard() {
        if (this.textToCopy) {
            this.clipboard.copy(this.textToCopy);
            this.snackBar.open('Text copied', undefined, { duration: 1800 });
        } else {
            let textToCopy = this.elementRef.nativeElement.textContent;
            if (textToCopy.endsWith('content_copy')) {
                textToCopy = textToCopy.substring(0, textToCopy.length - 'content_copy'.length);
            }
            this.clipboard.copy(textToCopy);
            this.snackBar.open('Text copied', undefined, { duration: 1800 });
        }
    }
}
