@if (!subscription.stores?.length) {
    <p>No stores were created for this subscription.</p>
}

<div class="actions">
    <div class="actions-left">
        @if (subscription.stores?.length > 0) {
            <div>
                <button mat-icon-button [matMenuTriggerFor]="filterMenu">
                    <mat-icon>filter_alt</mat-icon>
                </button>
                <mat-menu #filterMenu>
                    <button mat-menu-item (click)="toggleShowActiveOnly()">
                        @if (filter.showActiveOnly) {
                            <mat-icon>check_box</mat-icon>
                        }
                        @if (!filter.showActiveOnly) {
                            <mat-icon>check_box_outline_blank</mat-icon>
                        }
                        Only show active
                    </button>
                </mat-menu>
                <mat-form-field class="dense-2">
                    <mat-icon matPrefix>search</mat-icon>
                    <input
                        matInput
                        placeholder="search"
                        (keydown)="updateFilter()"
                        (keyup)="updateFilter()"
                        [(ngModel)]="filter.name"
                    />
                </mat-form-field>
                <mat-chip-listbox>
                    @if (filter.showActiveOnly) {
                        <mat-chip-option selectable="false" removable="true" (removed)="toggleShowActiveOnly()">
                            Active
                            <button matChipRemove aria-label="Remove filter: Active">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-option>
                    }
                </mat-chip-listbox>
            </div>
        }
    </div>

    <div class="actions-right">
        @if (canAddStore()) {
            <button
                mat-stroked-button
                (click)="openAddStoreDialog()"
                [disabled]="pageStatus === 'saving'"
                id="addStoreAction"
            >
                <mat-icon>add</mat-icon>
                Add Store
            </button>
        }
    </div>
</div>

@if (pageStatus === "saveError" && pageError) {
    <admin-error [error]="pageError"></admin-error>
}

@if (subscription.stores?.length) {
    <table mat-table class="stores-table mat-elevation-z4" #storesTable="matTable" [dataSource]="displayedStores">
        <mat-header-row
            *matHeaderRowDef="['id', 'environment', 'status', 'projectsCount', 'ordersCount', 'links']"
        ></mat-header-row>
        <mat-row
            *matRowDef="let store; columns: ['id', 'environment', 'status', 'projectsCount', 'ordersCount', 'links']"
        ></mat-row>
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let store">
                <div class="store-label">
                    <div>
                        <a tabindex="0" [routerLink]="['/stores', store.id, 'dashboards', 'overview']">{{ store.label }}</a>
                    </div>
                    <div>
                        <code adminCopyToClipboard>{{ store.id }}</code>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="displayName">
            <mat-header-cell *matHeaderCellDef>Display Name</mat-header-cell>
            <mat-cell *matCellDef="let store">{{ store.label }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="environment">
            <mat-header-cell *matHeaderCellDef>Environment</mat-header-cell>
            <mat-cell *matCellDef="let store">{{ store.environment }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let store">{{ store.status }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="projectsCount">
            <mat-header-cell *matHeaderCellDef>Projects (14d)</mat-header-cell>
            <mat-cell *matCellDef="let store">{{ store.projectsCount | number }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="ordersCount">
            <mat-header-cell *matHeaderCellDef>Orders (14d)</mat-header-cell>
            <mat-cell *matCellDef="let store">{{ store.ordersCount | number }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="links">
            <mat-header-cell *matHeaderCellDef>Links</mat-header-cell>
            <mat-cell *matCellDef="let store">
                <button
                    tabindex="0"
                    [routerLink]="['/stores', store.id, 'dashboards', 'overview']"
                    mat-icon-button
                    matTooltip="Overview"
                    class="mr-4"
                >
                    <mat-icon>dashboard</mat-icon>
                </button>
                @if (canManageStoreMetadata(store.id)) {
                    <button
                        tabindex="0"
                        [routerLink]="['/stores', store.id, 'settings', 'status']"
                        mat-icon-button
                        matTooltip="Store Status"
                        class="mr-4"
                    >
                        <mat-icon>business_center</mat-icon>
                    </button>
                }
                <button
                    tabindex="0"
                    [routerLink]="['/stores', store.id, 'lists', 'recent']"
                    mat-icon-button
                    matTooltip="Recent Orders"
                >
                    <mat-icon>inbox</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
    </table>
}
