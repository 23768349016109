<div class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div class="header accent">
            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0">
                <mat-icon
                    class="logo-icon s-32 mr-16"
                    [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
                >
                    {{ icon }}
                </mat-icon>
                <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">{{
                        title
                    }}</span>
            </div>
            <!-- / APP TITLE -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content-card">
            @switch (pageStatus) {
                @case ("loading") {
                    <div class="loader">
                        <mat-spinner></mat-spinner>
                    </div>
                }
                @case ("loadError") {
                    <div class="loader">
                        <admin-error [error]="pageError"></admin-error>
                    </div>
                }
                @default {
                    <ng-content></ng-content>
                }
            }
        </div>
        <!-- / CONTENT -->
    </div>
    <!-- /CENTER -->
</div>
