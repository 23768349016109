import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from 'app/shared/authentication';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, StoreRole, StoreUserRole, SubscriptionPermission } from 'app/shared/graph';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult } from 'app/shared/permission/add-authorized-user-dialog.component';
import { AdminComponentBase, PageSimpleContentComponent } from '../../../layout/content';
import { UsersRolesManagementComponent } from 'app/shared/permission/users-roles-management.component';
import { ErrorComponent } from 'app/shared/components/error.component';

@Component({
    templateUrl: './store-authorized-users-page.component.html',
    styleUrls: ['./store-authorized-users-page.component.scss'],
    imports: [PageSimpleContentComponent, UsersRolesManagementComponent, ErrorComponent]
})
export class StoreAuthorizedUsersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly authorizedUsersService = inject(AuthorizedUsersService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly authService = inject(AuthorizationService);
    private readonly dialog = inject(MatDialog);

    public storeId: string;

    public storeWithAuthorizedUsers?: Store;
    public storeRoles: StoreRole[] = [];
    public authContext: AuthorizationContext;

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });

        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;

            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.authorizedUsersService.getAuthorizedUsers(this.storeId),
                    this.authorizedUsersService.getAvailableRoles()
                ]),
                ([store, availableRoles]) => {
                    this.storeWithAuthorizedUsers = store;
                    this.storeRoles = availableRoles.storeRoles;
                });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    openAddStoreRole(store: Store, user?: StoreUserRole) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<StoreRole>, AddAuthorizedUserDialogData<StoreRole>, AddAuthorizedUserDialogResult<StoreRole>>(AddAuthorizedUserDialogComponent, {
            data: {
                email: user?.user?.email,
                target: {
                    availableRoles: this.storeRoles.filter(role => !user || !user.roles.includes(role)),
                    label: store.label || store.id
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRoleOnStore({
                email: result.email,
                storeAccess: { id: store.id, roles: [result.role] }
            }), user => {
                let existingUser = store.authorizedUsers.find(e => e.user?.email === user.email);
                if (existingUser) {
                    existingUser.roles.push(result.role);
                } else {
                    store.authorizedUsers.push({
                        user,
                        roles: [result.role]
                    });
                }
            });
        });
    }

    removeStoreRole(store: Store, email: string, role: StoreRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email,
                stores: [{ id: store.id, roles: [role] }]
            }), () => {
                let user = store.authorizedUsers.find(e => e.user?.email == email);
                if (user) {
                    let roleIndex = user.roles.indexOf(role);
                    if (roleIndex !== -1) {
                        user.roles.splice(roleIndex, 1);
                    }
                }
            });
        });
    }

    canManageUsers(subscriptionId: string) {
        return this.authContext.hasSubscriptionPermissions(subscriptionId, SubscriptionPermission.ManageAdminUsers);
    }
}
