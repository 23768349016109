<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Photo'"
    [icon]="'photo'"
>
    <div class="content">
        <div class="actions">
            <h2>Actions</h2>
            <div class="actions-buttons">
                @if (canManagePhoto()) {
                    <admin-save-button [pageStatus]="pageStatus" useContent="true" (save)="reEncodeImage(photoUrn)">
                        Re-Encode image
                    </admin-save-button>
                    @if (photoUrn.indexOf(":external") !== -1) {
                        <admin-save-button [pageStatus]="pageStatus" useContent="true" (save)="invalidateImageCache(photoUrn)">
                            Invalidate cache
                        </admin-save-button>
                    }
                }
            </div>
        </div>
        <div class="photo-info">
            <h2>Information</h2>
            URN: <code adminCopyToClipboard>{{ photoUrn }}</code>
        </div>
        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
    </div>
</admin-page-simple-content>
