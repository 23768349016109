<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Library: Products'"
    [icon]="'card_giftcard'"
>
    <div class="content">
        <mat-form-field class="dense-2" floatLabel="always">
            <mat-label>Filter</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [(ngModel)]="filter" (keydown)="updateFilter()" (keyup)="updateFilter()" />
            @if (products?.length) {
                <mat-hint>Showing {{ filteredProducts.length }} / {{ products.length }} products</mat-hint>
            }
        </mat-form-field>

        @for (product of filteredProducts; track product) {
            <div class="product-info">
                <h2 class="label">{{ product.label }}</h2>
                <div class="product-thumbnail">
                    @if (product.thumbnail) {
                        <img
                            [attr.alt]="'Thumbnail of ' + product.label"
                            loading="lazy"
                            [attr.src]="getThumbnailUrlWithStoreId(product)"
                        />
                    }
                    @if (!product.thumbnail) {
                        <span>No thumbnail</span>
                    }
                </div>
                <div class="info">
                    <div class="admin-field">
                        <span class="label">Module</span>
                        <span class="value"
                        ><code adminCopyToClipboard>{{ product.module }}</code></span
                        >
                    </div>
                    <div class="admin-field">
                        <span class="label">ID</span>
                        <span class="value"
                        ><code adminCopyToClipboard>{{ product.id }}</code></span
                        >
                    </div>
                    <div class="admin-field">
                        <span class="label">PLU</span>
                        <span class="value"
                        ><code adminCopyToClipboard>{{ product.plu }}</code></span
                        >
                    </div>
                    <div class="admin-field">
                        <span class="label">Links</span>
                        <span class="value">
                            <ul class="link-list">
                                <li>
                                    <a
                                        tabindex="0"
                                        [routerLink]="['/stores', storeId, 'library', 'product']"
                                        [queryParams]="{ id: product.id }"
                                    >Themes</a>
                                </li>
                                <li>
                                    <a tabindex="0" target="_blank" [href]="getGitlabLink(product.id)">GitLab</a>
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>
        }
    </div>
</admin-page-simple-content>
