import { Component, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FuseConfig } from '@fuse/types';
import { environment } from 'environments/environment';
import { NgClass } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FuseNavigationComponent } from '@fuse/components/navigation/navigation.component';

@Component({
    selector: 'navbar-vertical-style-2', // eslint-disable-line
    templateUrl: './style-2.component.html',
    styleUrls: ['./style-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgClass, MatIconButton, MatIcon, FuseNavigationComponent]
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy {
    private _fuseConfigService = inject(FuseConfigService);
    private _fuseNavigationService = inject(FuseNavigationService);
    private _fuseSidebarService = inject(FuseSidebarService);
    private _router = inject(Router);

    fuseConfig: FuseConfig;
    navigation: any;
    environmentName = environment.environment;

    private _unsubscribeAll: Subject<any>;

    constructor() {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                const sidebar = this._fuseSidebarService.getSidebar('navbar');
                if (sidebar) {
                    sidebar.close();
                }
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
            this.fuseConfig = config;
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar')!.toggleOpen();
    }

    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar')!.toggleFold();
    }
}
