<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Designer: Locales'"
    [icon]="'translate'"
>
    <div class="content">
        <div class="header">
            <div class="description">
                <p>
                    This page allows you to overrides the designer locales. You can find more details in the
                    <a
                        href="https://doc.mediaclip.ca/designer/localization-overrides/"
                        class="external"
                        rel="external"
                        target="_blank"
                    >
                        documentation
                        <mat-icon>open_in_new</mat-icon>
                    </a
                    >.
                </p>
            </div>
        </div>

        <div class="actions">
            <button mat-stroked-button (click)="addCulture()">
                <mat-icon>add</mat-icon>
                Add locale
            </button>
            <input
                #file
                type="file"
                multiple="multiple"
                (change)="updateLocalesFromFile($any($event.target).files)"
                accept="application/json"
                style="display: none"
            />
            <button mat-stroked-button (click)="file.click()">
                <mat-icon>upload</mat-icon>
                Load from files...
            </button>
            <admin-save-button
                [pageStatus]="pageStatus"
                (save)="saveAll()"
                useContent="true"
                [disabled]="!isCultureNotSaved()"
            >
                <mat-icon>save</mat-icon>&nbsp; Save All
            </admin-save-button>
        </div>

        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
        <div class="editor">
            <mat-accordion>
                @for (culture of cultures; track culture) {
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ culture }}
                                @if (isCultureNotSaved(culture)) {
                                    *
                                }
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="locale-editor">
                            <div class="locale-actions">
                                <input
                                    #file
                                    type="file"
                                    (change)="updateLocaleFromFile(culture, $any($event.target).files)"
                                    accept="application/json"
                                    style="display: none"
                                />
                                <button mat-stroked-button (click)="file.click()">
                                    <mat-icon>upload</mat-icon>
                                    Load from file...
                                </button>
                                <admin-save-button
                                    [pageStatus]="pageStatus"
                                    (save)="save(culture)"
                                    useContent="true"
                                    [disabled]="!isCultureNotSaved(culture)"
                                >
                                    <mat-icon>save</mat-icon>&nbsp; Save
                                </admin-save-button>
                            </div>
                            <code-editor
                                [(ngModel)]="localesContent[culture]"
                                theme="dark"
                                setup="basic"
                                language="json"
                                [languages]="languages"
                                (keydown)="handleSaveEditor($event, culture)"
                            ></code-editor>
                        </div>
                    </mat-expansion-panel>
                }
            </mat-accordion>
        </div>
    </div>
</admin-page-simple-content>
