import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import {
    GenericRemoteFilterData,
    HttpHeader,
    PerfectlyClearRemoteFilterData,
    PicsartEffectsRemoteFilterData,
    PicsartImageResponseFormat,
    PicsartLaserEngravingRemoteFilterData,
    PicsartRemoveBackgroundOutputType,
    PicsartRemoveBackgroundRemoteFilterData,
    PicsartRemoveBackgroundScale,
    PicsartStyleTransferLevel,
    PicsartStyleTransferRemoteFilterData,
    RemoteFilter,
    RemoteFilterKind
} from 'app/shared/graph';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { RemoteFilterLabelPipe } from './remote-filter-label.pipe';
import { MatToolbar } from '@angular/material/toolbar';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { ColorIndicatorDirective } from '../../../shared/utilities/color-indicator.directive';

export interface EditRemoteFilterDialogData {
    mode: 'create' | 'edit';
    remoteFilter?: RemoteFilter;
}

export interface EditRemoteFilterDialogResult {
    name: string;
    alias?: string | null;
    group?: string | null;
    visible: boolean;
    kind: RemoteFilterKind;
    data: any;
    order: number;
}

@Component({
    selector: 'admin-edit-remote-filter-dialog',
    templateUrl: './edit-remote-filter-dialog.component.html',
    styleUrls: ['../../../shared/utilities/full-screen-dialog.scss', './edit-remote-filter-dialog.component.scss'],
    imports: [FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatError, MatCheckbox, MatSelect, MatOption, MatIconButton, MatIcon, MatButton, SecretInputToggleComponent, MatSuffix, RemoteFilterLabelPipe, MatDialogClose, MatToolbar, MatRadioGroup, MatRadioButton, ColorIndicatorDirective]
})
export class EditRemoteFilterDialogComponent {
    dialogRef = inject<MatDialogRef<EditRemoteFilterDialogComponent>>(MatDialogRef);
    readonly data = inject<EditRemoteFilterDialogData>(MAT_DIALOG_DATA);

    public filterInfo: EditRemoteFilterDialogResult;
    public genericData: GenericRemoteFilterData = { url: '' };
    public perfectlyClearData: PerfectlyClearRemoteFilterData = { apiKey: '' };
    public picsartRemoveBackgroundData: PicsartRemoveBackgroundRemoteFilterData = {
        apiKey: '',
        outputType: PicsartRemoveBackgroundOutputType.Cutout,
        format: PicsartImageResponseFormat.Png
    };
    public picsartEffectsName: string[] = [
        'apr1',
        'apr2',
        'apr3',
        'brnz1',
        'brnz2',
        'brnz3',
        'cyber1',
        'cyber2',
        'dodger',
        'food1',
        'food2',
        'icy1',
        'icy2',
        'icy3',
        'mnch1',
        'mnch2',
        'mnch3',
        'nature1',
        'nature2',
        'noise',
        'ntrl1',
        'ntrl2',
        'pixelize',
        'popart',
        'saturation',
        'sft1',
        'sft2',
        'sft3',
        'sft4',
        'shadow1',
        'shadow2',
        'sketcher1',
        'sketcher2',
        'tl1',
        'tl2',
        'urban1',
        'urban2',
        'water1',
        'water2'
    ];
    public picsartRmBackgroundOutputTypes: { value: PicsartRemoveBackgroundOutputType | null; display: string }[];
    public picsartFormats: { display: string; value: PicsartImageResponseFormat | null }[];
    public picsartRmBackgroundScales: { display: string; value: PicsartRemoveBackgroundScale | null }[];
    public picsartRmStyleTransferLevels: { display: string; value: PicsartStyleTransferLevel | null }[];
    public picsartEffectsData: PicsartEffectsRemoteFilterData = {
        apiKey: '',
        effect: this.picsartEffectsName[0]
    };
    public picsartStyleTransferData: PicsartStyleTransferRemoteFilterData = {
        apiKey: '',
        level: PicsartStyleTransferLevel.L1,
        referenceImageUrl: ''
    };
    public picsartLaserEngravingData: PicsartLaserEngravingRemoteFilterData = {
        apiKey: '',
        engraveColor: undefined,
        backgroundColor: undefined,
        format: PicsartImageResponseFormat.Png,
    };

    constructor() {
        const data = this.data;

        this.initEnums();
        if (data.remoteFilter) {
            this.filterInfo = {
                name: data.remoteFilter.name,
                alias: data.remoteFilter.alias,
                visible: data.remoteFilter.visible,
                kind: data.remoteFilter.kind,
                order: data.remoteFilter.order,
                group: data.remoteFilter.group,
                data: { ...data.remoteFilter.data }
            };
            if (data.remoteFilter.kind === RemoteFilterKind.Generic) {
                this.genericData = { ...data.remoteFilter.data } as any;
            } else if (data.remoteFilter.kind === RemoteFilterKind.PerfectlyClear) {
                this.perfectlyClearData = { ...data.remoteFilter.data } as any;
            } else if (data.remoteFilter.kind === RemoteFilterKind.PicsartRemoveBackground) {
                this.picsartRemoveBackgroundData = { ...data.remoteFilter.data } as any;
            } else if (data.remoteFilter.kind === RemoteFilterKind.PicsartEffects) {
                this.picsartEffectsData = { ...data.remoteFilter.data } as any;
            } else if (data.remoteFilter.kind === RemoteFilterKind.PicsartStyleTransfer) {
                this.picsartStyleTransferData = { ...data.remoteFilter.data } as any;
            } else if (data.remoteFilter.kind === RemoteFilterKind.PicsartLaserEngraving) {
                this.picsartLaserEngravingData = { ...data.remoteFilter.data } as any;
            }
        } else {
            this.filterInfo = {
                alias: null,
                name: '',
                visible: true,
                kind: RemoteFilterKind.Generic,
                order: 1,
                data: {}
            };
        }
    }

    initEnums(): void {
        this.picsartRmBackgroundOutputTypes = Object.keys(PicsartRemoveBackgroundOutputType)
            .map(key => {
                    return {
                        value: PicsartRemoveBackgroundOutputType[key as keyof typeof PicsartRemoveBackgroundOutputType],
                        display: key
                    };
                }
            );
        this.picsartRmBackgroundOutputTypes.unshift({ value: null, display: '' });
        this.picsartFormats = Object.keys(PicsartImageResponseFormat)
            .map(key => {
                    return {
                        value: PicsartImageResponseFormat[key as keyof typeof PicsartImageResponseFormat],
                        display: key
                    };
                }
            );
        this.picsartFormats.unshift({ value: null, display: '' });
        this.picsartRmBackgroundScales = Object.keys(PicsartRemoveBackgroundScale)
            .map(key => {
                    return {
                        value: PicsartRemoveBackgroundScale[key as keyof typeof PicsartRemoveBackgroundScale],
                        display: key
                    };
                }
            );
        this.picsartRmBackgroundScales.unshift({ value: null, display: '' });

        this.picsartRmStyleTransferLevels = Object.keys(PicsartStyleTransferLevel)
            .map(key => {
                    return {
                        value: PicsartStyleTransferLevel[key as keyof typeof PicsartStyleTransferLevel],
                        display: key
                    };
                }
            );
    }

    addHeader(): void {
        if (!this.genericData.headers) {
            this.genericData.headers = [];
        }
        this.genericData.headers.push({ name: '', value: '' });
    }

    valid() {
        if (this.filterInfo.kind === RemoteFilterKind.Generic) {
            this.filterInfo.data = this.genericData;
        } else if (this.filterInfo.kind === RemoteFilterKind.PerfectlyClear) {
            this.filterInfo.data = this.perfectlyClearData;
        } else if (this.filterInfo.kind === RemoteFilterKind.PicsartEffects) {
            this.filterInfo.data = this.picsartEffectsData;
        } else if (this.filterInfo.kind === RemoteFilterKind.PicsartRemoveBackground) {
            this.filterInfo.data = this.picsartRemoveBackgroundData;
        } else if (this.filterInfo.kind === RemoteFilterKind.PicsartStyleTransfer) {
            this.filterInfo.data = this.picsartStyleTransferData;
        } else if (this.filterInfo.kind === RemoteFilterKind.PicsartLaserEngraving) {
            this.filterInfo.data = this.picsartLaserEngravingData;
        }
        this.dialogRef.close(this.filterInfo);
    }

    removeHeader(header: HttpHeader) {
        this.genericData.headers?.splice(this.genericData.headers?.indexOf(header), 1);
    }

    protected readonly RemoteFilterKind = RemoteFilterKind;
}
