import { AuthenticatedUser } from 'app/shared/graph';
import { AuthenticationService } from 'app/shared/authentication';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'admin-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    animations: fuseAnimations,
    imports: [MatIcon, AsyncPipe]
})
export class HomePageContainerComponent implements OnInit {
    private readonly authenticationService = inject(AuthenticationService);

    me$: Observable<AuthenticatedUser>;

    ngOnInit(): void {
        this.me$ = this.authenticationService.authenticatedUser$;
    }
}
