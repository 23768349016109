<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Manage Designer Branch'"
    [icon]="'public'"
>
    <div class="content">
        <mat-card class="mat-card">
            <mat-card-header>
                <mat-card-title>Pre-deployment Stable</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>Before upgrading to a new version of Stable, use this action.</p>
                <p>
                    This will upgrade all the stores on <code>Stable</code> with the
                    <a [routerLink]="['/stores', '_', 'settings', 'status']">Automatic Designer Upgrade</a> disabled,
                    from the release channel <code>Stable</code> to <code>DeprecatedStable</code>.
                </p>
            </mat-card-content>
            <mat-card-actions align="end">
                <admin-save-button [pageStatus]="pageStatus" (save)="updateToDeprecated()" [disabled]="!canManage()">
                    Switch to Deprecated Stable branch
                </admin-save-button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="mat-card">
            <mat-card-header>
                <mat-card-title>Post-deployment Stable (8 weeks later)</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>Use this 8 weeks after the previous action.</p>
                <p>
                    This button will upgrade all the stores on the release channel <code>DeprecatedStable</code> to
                    <code>Stable</code>.
                </p>
            </mat-card-content>
            <mat-card-actions align="end">
                <admin-save-button [pageStatus]="pageStatus" (save)="updateToStable()" [disabled]="!canManage()">
                    Update to Stable branch
                </admin-save-button>
            </mat-card-actions>
        </mat-card>
    </div>
</admin-page-simple-content>
