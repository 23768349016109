import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedUser, GraphDotnetService, graphql } from '../graph';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MeService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getUserInfo(): Observable<AuthenticatedUser> {
        return this.graphDotnetService.query(graphql`
                query getUserInfo {
                    me {
                        email
                        picture
                        isMfaEnabled
                        globalPermissions {
                            storePermissions
                            subscriptionPermissions
                            fulfillerPermissions
                            libraryPermissions
                            globalPermissions
                        }
                        storePermissions {
                            storeId
                            storePermissions
                        }
                        subscriptionPermissions {
                            subscriptionId
                            subscriptionPermissions
                            storePermissions
                        }
                        fulfillerPermissions {
                            fulfillerId
                            fulfillerPermissions
                        }
                        libraryPermissions {
                            libraryOwner
                            libraryPermissions
                        }
                        stores {
                            id
                            label
                            environment
                            status
                            subscription {
                                id
                                label
                            }
                        }
                        fulfillers {
                            id
                        }
                        libraries {
                            owner
                        }
                        subscriptions {
                            id
                            label
                        }
                    }
                }
            `
        ).pipe(map(x => x.me));
    }

    signOut(): Observable<void> {
        return this.graphDotnetService.mutate<void>(graphql`
                mutation signOut {
                    signOut
                }
            `
        ).pipe(map(a => a.signOut));
    }
}
