import { ActivatedRoute } from '@angular/router';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { AdminComponentBase, PageSimpleContentComponent, PageStatus } from '../../../layout/content';
import { MatButton } from '@angular/material/button';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { FormsModule } from '@angular/forms';
import { DesignerCustomizationsService } from './designer-customizations.service';
import { MatIcon } from '@angular/material/icon';
import { CodeEditor } from '@acrodata/code-editor';
import { languages } from '@codemirror/language-data';

@Component({
    selector: 'admin-store-designer-custom-javascript',
    templateUrl: './store-designer-custom-javascript.component.html',
    styleUrls: ['./store-designer-custom-javascript.component.scss'],
    imports: [PageSimpleContentComponent, MatButton, SaveButtonComponent, ErrorComponent, FormsModule, MatIcon, CodeEditor]
})
export class StoreDesignerCustomJavascriptComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly designerCustomizationsService = inject(DesignerCustomizationsService);

    javascript: string;
    storeId: string;
    languages = languages

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId), (storeFiles) => {
                if (!storeFiles.javascript?.url) {
                    this.javascript = '';
                    this.pageStatus = PageStatus.loaded;
                } else {
                    this.designerCustomizationsService
                        .getCustomizationFile(storeFiles.javascript?.url)
                        .subscribe({
                            next: (javascript) => {
                                this.javascript = javascript;
                                this.pageStatus = PageStatus.loaded;
                            },
                            error: (err) => {
                                this.pageError = err;
                                this.pageStatus = PageStatus.loadError;
                            }
                        });
                }
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    save(): void {
        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateJavascriptFile({
            storeId: this.storeId,
            content: this.javascript
        }));
    }

    async onFilesChanged(files: FileList) {
        if (!files.length) {
            return;
        }
        this.javascript = await files[0].text();
    }

    handleSaveEditor(event: KeyboardEvent) {
        if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault(); // Prevent browser's save dialog
            this.save();
        }
    }
}
