import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { CodeEditor } from '@acrodata/code-editor';
import { languages } from '@codemirror/language-data';

export interface DisplayCodeDialogData {
    text: string;
    language: string;
    editable?: boolean;
}

export type DisplayCodeDialogResult = {
    text: string;
}

@Component({
    selector: 'admin-display-code-dialog',
    templateUrl: './display-code-dialog.component.html',
    styleUrls: ['./display-code-dialog.component.scss'],
    imports: [MatDialogContent, FormsModule, MatDialogActions, MatButton, MatDialogClose, CodeEditor]
})
export class DisplayCodeDialogComponent {
    dialogRef = inject<MatDialogRef<DisplayCodeDialogComponent, DisplayCodeDialogResult>>(MatDialogRef);
    data = inject<DisplayCodeDialogData>(MAT_DIALOG_DATA);

    text: string;
    editable: boolean;
    languages = languages

    constructor() {
        const data = this.data;

        this.text = data.text;
        this.editable = !!data.editable;
    }

    edit() {
        this.dialogRef.close({ text: this.text });
    }
}
