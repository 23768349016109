import { Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfig } from '@fuse/types';
import { Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { QuickPanelComponent } from './right-panel/quick-panel.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'layout', // eslint-disable-line
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgTemplateOutlet, FuseSidebarComponent, QuickPanelComponent, ToolbarComponent, NgClass, NavbarComponent, AsyncPipe, RouterOutlet]
})
export class LayoutComponent implements OnInit, OnDestroy {
    private _fuseConfigService = inject(FuseConfigService);

    fuseConfig: FuseConfig;
    navigation: any;
    quickPanelOpened$: Subject<boolean>;
    lockedOpen = [Breakpoints.Large, Breakpoints.XLarge]

    private _unsubscribeAll: Subject<any>;

    constructor() {
        // Set the defaults

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.quickPanelOpened$ = new Subject<boolean>();
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
            this.fuseConfig = config;
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    quickPanelOpenedChanged(isOpened: boolean): void {
        this.quickPanelOpened$.next(isOpened);
    }
}
