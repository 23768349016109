import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase, PageSimpleContentComponent } from '../../../layout/content';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddLocaleDialogComponent } from './add-locale-dialog.component';
import { forkJoin } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { DesignerCustomizationsService } from './designer-customizations.service';
import { CodeEditor } from '@acrodata/code-editor';
import { languages } from '@codemirror/language-data';

@Component({
    templateUrl: './store-designer-custom-locales-component.component.html',
    styleUrls: ['./store-designer-custom-locales-component.component.scss'],
    imports: [PageSimpleContentComponent, MatIcon, MatButton, SaveButtonComponent, ErrorComponent, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, FormsModule, CodeEditor]
})
export class StoreDesignerCustomLocalesComponentComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly designerCustomizationsService = inject(DesignerCustomizationsService);
    private readonly dialog = inject(MatDialog);

    storeId: string;
    cultures: string[] = [];
    localesContent: Record<string, string> = {};
    savedCulture: Record<string, string> = {};
    languages = languages

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId),
                customization => {
                    this.subscribeWithGenericLoadingErrorHandling(
                        forkJoin(customization.locales.map(s => this.designerCustomizationsService.getCustomizationFile(s.url))), (files) => {
                            for (let i = 0; i < customization.locales.length; i++) {
                                let locale = customization.locales[i];
                                let content = files[i];
                                this.localesContent[locale.culture] = content;
                                this.savedCulture[locale.culture] = content;
                            }
                            this.cultures = customization.locales.map(x => x.culture);

                        });
                });
        }));
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    addCulture() {
        this.dialog.open(AddLocaleDialogComponent).afterClosed().subscribe((result: string) => {
            if (!result) {
                return;
            }
            let culture = result.toLowerCase();
            if (culture in this.localesContent) {
                return;
            }
            this.cultures.push(culture);
            this.localesContent[culture] = '{\n}';
        });
    }

    save(culture: string) {
        let content = this.localesContent[culture];
        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateLocale({
            storeId: this.storeId,
            content: content,
            culture: culture
        }), () => {
            this.savedCulture[culture] = content;
        });
    }

    saveAll() {
        let cultureToSave = this.cultures
            .filter(culture => this.isCultureNotSaved(culture))
            .map(culture => this.designerCustomizationsService.updateLocale({
                storeId: this.storeId,
                content: this.localesContent[culture],
                culture: culture
            }));
        this.subscribeWithGenericSavinErrorHandling(forkJoin(cultureToSave), () => {
            for (let culture of this.cultures) {
                this.savedCulture[culture] = this.localesContent[culture];
            }
        });
    }

    isCultureNotSaved(culture?: string): boolean {
        if (culture) {
            return this.localesContent[culture] !== this.savedCulture[culture];
        }
        for (let culture of this.cultures) {
            if (this.localesContent[culture] !== this.savedCulture[culture])
                return true;
        }
        return false;
    }

    async updateLocaleFromFile(culture: string, files: FileList) {
        if (!files.length) {
            return;
        }
        this.localesContent[culture] = await files[0].text();
    }

    async updateLocalesFromFile(files: FileList) {
        if (!files.length) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let content = await file.text();
            const cultureMatch = file.name.match(/\b[a-z]{2}(-[A-Z]{2})?\b/i);
            if (!cultureMatch) {
                continue;
            }
            const culture = cultureMatch[0];
            this.localesContent[culture] = content;
            if (!this.cultures.includes(culture)) {
                this.cultures.push(culture);
            }
        }
    }

    handleSaveEditor(event: KeyboardEvent, culture: string) {
        if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault(); // Prevent browser's save dialog
            this.save(culture);
        }
    }
}
