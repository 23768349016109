import version from './version';
import { IEnvironment } from './environment.types';

export const environment = <IEnvironment>{
    production: true,
    version: version,
    animations: true,
    environment: 'Staging',
    appInsights: {
        connectionString: 'InstrumentationKey=179027a1-884a-42e9-b722-776e6e338265;IngestionEndpoint=https://eastus-1.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=5463ddaf-1e09-46e1-bb56-b16d6df79c5f',
    },
    auth0: {
        enabled: true,
        clientID: 'CQeRlJI8av5IGUfDG5FTAKuSb36qFWwv',
        domain: 'stg-mediaclip.auth0.com',
        audience: 'https://graph.staging.mediacliphub.com',
        redirectPath: '/signin-auth0',
    },
    endpoints: {
        graphDotnetUrl: 'https://graph.staging.mediacliphub.com/graphql',
    },
    hub: {
        hubApiUrl: 'https://api.staging.mediacliphub.com',
        renderUrl: 'https://render.staging.mediacliphub.com',
    },
};
