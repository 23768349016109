import { Component, inject, OnInit } from '@angular/core';
import { Library } from 'app/shared/graph';
import { PromptDialogService } from 'app/shared/dialog/prompt-dialog.service';
import { AdminComponentBase, PageSimpleContentComponent } from '../../../layout/content';
import { ActivatedRoute, Router } from '@angular/router';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatDivider } from '@angular/material/divider';
import { MatRipple } from '@angular/material/core';
import { LibraryUpdateService } from './library-update.service';
import { InteractDirective } from '../../../shared/utilities/interact.directive';

@Component({
    selector: 'admin-libraries-page',
    templateUrl: './libraries-page.component.html',
    styleUrls: ['./libraries-page.component.scss'],
    imports: [PageSimpleContentComponent, MatFormField, MatIcon, MatPrefix, MatLabel, MatInput, FormsModule, MatButton, ErrorComponent, MatDivider, MatRipple, InteractDirective]
})
export class LibrariesPageComponent extends AdminComponentBase implements OnInit {
    private readonly promptService = inject(PromptDialogService);
    private readonly libraryUpdateService = inject(LibraryUpdateService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    public libraries: Library[] = [];
    public filter = '';
    public displayedLibraries: Library[] = [];

    ngOnInit(): void {
        this.subscribeWithGenericLoadingErrorHandling(this.libraryUpdateService.getLibraries(), (libraries) => {
            this.libraries = libraries;
            this.updateDisplayedLibraries();
        });
    }

    displayLibrary(owner: string) {
        this.router.navigate([owner], { relativeTo: this.route });
    }

    deployNewLibrary() {
        this.promptService.prompt('Deploy a new library', 'The name of the library (should match the name of the Git repository)', {
            fieldLabel: 'Owner'
        }).subscribe(result => {
            this.router.navigate([result], { relativeTo: this.route });
        });
    }

    updateDisplayedLibraries() {
        if (!this.filter) {
            this.displayedLibraries = this.libraries.sort((a, b) => a.owner.localeCompare(b.owner));
            return;
        }
        this.displayedLibraries = this.libraries
            .filter(l => l.owner.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1)
            .sort((a, b) => a.owner.localeCompare(b.owner));

    }
}
