import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';


@Directive({
    selector: '[adminColorIndicator]',
    standalone: true
})
export class ColorIndicatorDirective implements OnChanges {
    @Input() indicatorColor: string = '';
    @Input() indicatorSize: number = 16;
    @Input() marginRight: number = 16;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.setupStyles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['indicatorColor'] || changes['indicatorSize'] || changes['marginRight']) {
            this.setupStyles();
        }
    }

    private setupStyles(): void {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'inline-block');
        this.renderer.setStyle(this.el.nativeElement, 'width', `${this.indicatorSize}px`);
        this.renderer.setStyle(this.el.nativeElement, 'height', `${this.indicatorSize}px`);
        this.renderer.setStyle(this.el.nativeElement, 'border-radius', `${this.indicatorSize / 2}px`);
        this.renderer.setStyle(this.el.nativeElement, 'margin-right', `${this.marginRight}px`);
        this.renderer.setStyle(this.el.nativeElement, 'background-color', this.indicatorColor);
    }
}
