<mat-toolbar color="accent">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <span>Edit Fulfiller</span>
    <button mat-button [disabled]="!form.valid" (click)="valid()">SAVE</button>
</mat-toolbar>

<div class="content mat-typography">
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Client</mat-label>
            <mat-select [(ngModel)]="fulfillerInfo.client" name="client" required>
                @for (client of clients; track client) {
                    <mat-option [value]="client">{{ client }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Library Package</mat-label>
            <input
                name="libraryPackage"
                placeholder="mediaclip/default"
                pattern="^[a-zA-Z0-9\-_]+\/[a-zA-Z0-9\-_]+$"
                matInput
                [(ngModel)]="fulfillerInfo.libraryPackage"
            />
            <mat-error>Value must be in the form <code>company/package</code></mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Base Url</mat-label>
            <input name="url" matInput placeholder="https://" [(ngModel)]="fulfillerInfo.url" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Fulfiller App Id</mat-label>
            <input name="fulfillerAppId" matInput [(ngModel)]="fulfillerInfo.fulfillerAppId" />
        </mat-form-field>
        @if (fulfillerInfo.client === "Fuji") {
            <mat-form-field>
                <mat-label>App key</mat-label>
                <input name="appKey" matInput #secretInput [(ngModel)]="fulfillerInfo.appKey" />
                <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                <mat-hint>Leave empty to keep the same value</mat-hint>
            </mat-form-field>
        }

        <label>Mapping Script</label>

        <code-editor
            [(ngModel)]="fulfillerInfo.mappingScript"
            name="mappingScript"
            [contentEditable]="true"
            setup="basic"
            language="JavaScript"
        ></code-editor>
    </form>
</div>
