import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { GlobalPermission, Subscription, SubscriptionPermission } from 'app/shared/graph';
import { fuseAnimations } from '@fuse/animations';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { MatDialog } from '@angular/material/dialog';
import { AddSubscriptionDialogComponent, AddSubscriptionDialogResult } from './add-subscription-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { RouterLink } from '@angular/router';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { PageStatus } from '../../../layout/content/page-status';
import { SubscriptionAdminService } from './subscription-admin.service';

@Component({
    selector: 'admin-subscriptions-page',
    templateUrl: './subscriptions-page.component.html',
    styleUrls: ['./subscriptions-page.component.scss'],
    animations: fuseAnimations,
    imports: [PageSimpleContentComponent, MatIconButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, MatFormField, MatPrefix, MatLabel, MatInput, FormsModule, MatChipRemove, MatButton, MatTable, MatColumnDef, MatHeaderCell, MatCell, RouterLink, CopyToClipboardComponent, MatHeaderRow, MatRow, MatHeaderCellDef, MatCellDef, MatHeaderRowDef, MatRowDef, MatChipListbox, MatChipOption]
})
export class SubscriptionsPageContainerComponent extends AdminComponentBase implements OnInit {
    private readonly subscriptionAdminService = inject(SubscriptionAdminService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly dialog = inject(MatDialog);

    @ViewChild('subscriptionTable', { static: false })
    subscriptionTable: MatTable<any>;

    subscriptions: Subscription[] = [];
    displayedSubscription: Subscription[] = [];
    authContext: AuthorizationContext;

    filter: {
        name?: string;
        showBillableOnly: boolean;
        showActiveOnly: boolean,
    } = {
        showActiveOnly: true,
        showBillableOnly: false
    };
    displayedColumns: string[] = [
        'label',
        'contact',
        'billable',
        'storeCount'
    ];

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        const savedFilter = localStorage.getItem('subscriptions-page-filter');
        if (savedFilter) {
            this.filter = { ...this.filter, ...JSON.parse(savedFilter) as any };
        }
        this.subscriptionAdminService.list().subscribe({
            next: subscriptions => {
                this.subscriptions = subscriptions;
                this.pageStatus = PageStatus.loaded;
                this.updateDisplayedSubscription();
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });
    }

    updateDisplayedSubscription() {
        this.displayedSubscription = this.subscriptions
            .filter(s => this.isSubscriptionVisible(s))
            .sort((a, b) => a.label.localeCompare(b.label));
        this.subscriptionTable.renderRows();
    }

    toggleShowActiveOnly() {
        this.filter.showActiveOnly = !this.filter.showActiveOnly;
        this.updateFilter();
    }

    toggleShowBillableOnly() {
        this.filter.showBillableOnly = !this.filter.showBillableOnly;
        this.updateFilter();
    }

    public updateFilter() {
        localStorage.setItem('subscriptions-page-filter', JSON.stringify({ ...this.filter, name: undefined }));
        this.updateDisplayedSubscription();
    }

    private isSubscriptionVisible(s: Subscription) {
        if (this.filter.showBillableOnly) {
            if (!s.billing?.billingStartDateUtc) {
                return false;
            }
        }
        if (this.filter.showActiveOnly) {
            if (!s.active) {
                return false;
            }
        }
        if (this.filter.name) {
            if (!s.label.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;

    }

    subscriptionId(i: number, s: Subscription) {
        return s.id;
    }

    canReadInvoice(subscription: Subscription): boolean {
        return this.authContext.hasSubscriptionPermissions(subscription.id, SubscriptionPermission.ReadInvoices);
    }

    $subscription(subscription: any): Subscription {
        return subscription as Subscription;
    }

    canCreateSubscriptions() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.CreateSubscription);
    }

    reload(){
        this.subscribeWithGenericLoadingErrorHandling(this.subscriptionAdminService.list(), subscriptions => {
            this.subscriptions = subscriptions;
        });
    }
    addSubscriptions() {
        this.dialog.open<AddSubscriptionDialogComponent, void, AddSubscriptionDialogResult>(AddSubscriptionDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionAdminService.createSubscription({
                label: result.subscriptionLabel,
            }), (response) => {
                this.subscriptions.push(response);
                this.updateDisplayedSubscription()
            });
        });
    }
}
