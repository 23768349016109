<admin-page-simple-content
    id="stock-photo-sources"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Stock Photo Sources'"
    [icon]="'image'"
>
    <div class="content">
        <div class="header">
            <mat-form-field class="dense-2" floatLabel="always">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Filter</mat-label>
                <input matInput (keydown)="updateFilter()" (keyup)="updateFilter()" [(ngModel)]="filter.name" />
            </mat-form-field>
            @if (canManageStockPhotoSource()) {
                <button mat-stroked-button (click)="addStockPhotoSource()">
                    <mat-icon>add</mat-icon>
                    Add stock photo source
                </button>
            }
        </div>

        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }

        <table
            mat-table
            #stockPhotoSourcesTable="matTable"
            [dataSource]="displayedStockPhotoSources"
            [trackBy]="stockPhotoSourceId"
            class="sources-table mat-elevation-z4"
        >
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                <mat-cell *matCellDef="let stockPhotoSource">
                    <div class="source-label">
                        <div>
                            <a tabindex="0" [routerLink]="'./' + stockPhotoSource.id">{{
                                    $stockPhotoSource(stockPhotoSource).label || $stockPhotoSource(stockPhotoSource).id
                                }}</a>
                        </div>
                        <div>
                            <code adminCopyToClipboard>{{ $stockPhotoSource(stockPhotoSource).id }}</code>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="visibility">
                <mat-header-cell *matHeaderCellDef>Visibility</mat-header-cell>
                <mat-cell *matCellDef="let stockPhotoSource">
                    @if (stockPhotoSource.visible) {
                        <mat-icon>check</mat-icon>
                    }
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="photosEndpointUrl">
                <mat-header-cell *matHeaderCellDef>Photos Endpoint</mat-header-cell>
                <mat-cell *matCellDef="let stockPhotoSource" adminCopyToClipboard>
                    {{ $stockPhotoSource(stockPhotoSource).photosEndpoint.url }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="enabled">
                <mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
                <mat-cell *matCellDef="let stockPhotoSource">
                    @if (!stockPhotoSource.disabled) {
                        <mat-icon>check</mat-icon>
                    }
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let stockPhotoSource">
                    <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-button">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                        <ng-container>
                            @if (canManageStockPhotoSource()) {
                                <button mat-menu-item (click)="showRelatedStores(stockPhotoSource.id)">
                                    <mat-icon>manage_search</mat-icon>
                                    Related Stores
                                </button>
                            }
                            @if (canManageStockPhotoSource()) {
                                <button mat-menu-item [routerLink]="'./' + stockPhotoSource.id">
                                    <mat-icon>edit</mat-icon>
                                    Edit
                                </button>
                            }
                        </ng-container>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
    </div>
</admin-page-simple-content>
