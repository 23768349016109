import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { OrdersService } from './orders.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { Order } from 'app/shared/graph';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { MatRipple } from '@angular/material/core';
import { LineStatusComponent } from './line-status.component';
import { DatePipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { PageStatus } from '../../../layout/content';
import { InteractDirective } from '../../../shared/utilities/interact.directive';

@Component({
    selector: 'admin-recent-order-page',
    templateUrl: './recent-order-page.component.html',
    styleUrls: ['./recent-order-page.component.scss'],
    imports: [PageSimpleContentComponent, MatTable, MatColumnDef, MatHeaderCell, MatCell, MatRipple, LineStatusComponent, MatHeaderRow, MatRow, DatePipe, FromNowPipe, MatHeaderCellDef, MatCellDef, MatHeaderRowDef, MatRowDef, InteractDirective]
})
export class RecentOrderPageComponent implements OnInit, OnDestroy {
    private readonly orderService = inject(OrdersService);
    private readonly route = inject(ActivatedRoute);
    public readonly router = inject(Router);

    private subscription = new Subscription();
    public pageStatus: PageStatus = PageStatus.loading;
    public pageError?: Error;
    public orders: Order[] = [];
    public columnsToDisplay: string[] = ['id', 'dateCreatedUtc'];

    ngOnInit(): void {
        this.subscription.add(this.route.params.subscribe(params => {
                this.orderService.getRecentOrders(params['storeId']).subscribe({
                    next: orders => {
                        this.orders = orders;
                        this.pageStatus = PageStatus.loaded;
                    },
                    error: error => {
                        this.pageError = error;
                        this.pageStatus = PageStatus.loadError;
                    }
                });
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    order$(order: any): Order {
        return order as Order;
    }
}
