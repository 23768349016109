<admin-page-simple-content
    id="external-photo-sources"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="title"
    [icon]="'photo'"
>
    <div class="content">
        @if (builtInExternalSource) {
            <section class="edit-form">
                <div class="title">
                    <h2 class="mat-h2">
                        @if (builtInExternalSource.icon) {
                            <img [src]="builtInExternalSource.icon" />
                        }
                        <p>{{ builtInExternalSource.label }}</p>
                    </h2>
                </div>
                <p>
                    You can find more information about the following fields in the
                    @switch (photoSourceId) {
                        @case ("facebook") {
                            <a href="https://doc.mediaclip.ca/hub/photo-sources/facebook/" target="_blank"
                            >documentation</a
                            >
                        }
                        @case ("instagram-graph") {
                            <a href="https://doc.mediaclip.ca/hub/photo-sources/instagram/" target="_blank"
                            >documentation</a
                            >
                        }
                        @case ("google-photos") {
                            <a href="https://doc.mediaclip.ca/hub/photo-sources/google-photos/" target="_blank"
                            >documentation</a
                            >
                        }
                        @case ("google-photos-picker") {
                            <a href="https://doc.mediaclip.ca/hub/photo-sources/google-photos/" target="_blank"
                            >documentation</a
                            >
                        }
                        @default {
                            <a href="https://doc.mediaclip.ca/hub/photo-sources/" target="_blank">documentation</a>
                        }
                    }
                </p>
                <form (ngSubmit)="save()" #form="ngForm">
                    <mat-form-field>
                        <mat-label>App Id</mat-label>
                        <input
                            matInput
                            [(ngModel)]="builtInExternalSource.appId"
                            required
                            autocomplete="off"
                            name="appId"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>App Secret</mat-label>
                        <input
                            matInput
                            #secretInput
                            [(ngModel)]="appSecret"
                            autocomplete="off"
                            name="appSecret"
                            [required]="mode === 'create'"
                        />
                        <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                        <mat-hint>Leave this field blank to keep current secret</mat-hint>
                    </mat-form-field>
                    @if (photoSourceId === "google-photos") {
                        <section class="google-photos">
                            <div class="google-login-redirect-url">
                                <mat-checkbox #useGoogleLoginPage [checked]="!!googleLoginRedirectUrl">
                                    Use custom login page
                                </mat-checkbox>
                                @if (useGoogleLoginPage.checked) {
                                    <mat-form-field>
                                        <mat-label>Redirect url</mat-label>
                                        <input
                                            matInput
                                            [(ngModel)]="googleLoginRedirectUrl"
                                            autocomplete="off"
                                            name="googleLoginRedirectUrl"
                                            pattern="^https://.+"
                                            required
                                            [disabled]="!useGoogleLoginPage.checked"
                                        />
                                        <mat-error>Should starts with https://</mat-error>
                                    </mat-form-field>
                                }
                            </div>
                            <p>Filters:</p>
                            <code-editor
                                [(ngModel)]="googlePhotosFilters"
                                [ngModelOptions]="{ standalone: true }"
                                [languages]="languages"
                                language="json"
                                theme="dark"
                                setup="basic"
                            ></code-editor>
                        </section>
                    }
                    <admin-save-button [disabled]="!form.valid" [pageStatus]="pageStatus">Save</admin-save-button>
                </form>
            </section>
        }

        <div>
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
        </div>
    </div>
</admin-page-simple-content>
