import { Injectable, inject } from '@angular/core';
import {
    ArchivedOrderLineLog,
    CreateOrderInput,
    graphql,
    Maybe,
    MediaclipMutationCreateOrderArgs,
    MediaclipMutationForceRerenderOrderLineArgs,
    MediaclipMutationRetryFailedOrderLinesArgs,
    MediaclipMutationSetOrderLineStatusArgs,
    MediaclipMutationSetOrderLineTrackingInformationArgs,
    MediaclipMutationUpdateRawOrderDataArgs,
    Order,
    OrderLineLogUnion,
    OrderLineStatusFlags,
    OrderLineStatusValues,
    OrderLineUnion,
    OrderUnion, SetOrderLineTrackingInformationInput,
    UpdateOrderRawDataInput
} from 'app/shared/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class OrdersService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getLines(storeId: string, flag: OrderLineStatusFlags | null): Observable<OrderLineUnion[]> {
        return this.graphDotnetService.query(graphql`
                query getLines($storeId: String!, $flag: OrderLineStatusFlags) {
                    store(id: $storeId) {
                        lines(flag: $flag) {
                            ... on OrderLine {
                                __typename
                                id
                                storeOrderLineNumber
                                storeOrderLineId
                                fulfillerHubLineNumber
                                dateCreatedUtc
                                store {
                                    id
                                }
                                order {
                                    id
                                    storeOrderId
                                }
                                status {
                                    value
                                    effectiveDateUtc
                                    flags
                                }
                            }
                        }
                    }
                }
            `,
            { storeId, flag }
        ).pipe(map((result) => (result.store && result.store.lines) || []));
    }

    getOrder(orderId: string): Observable<Maybe<OrderUnion> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getOrder($orderId: UUID!) {
                    order(id: $orderId) {
                        ... on Order {
                            __typename
                            id
                            storeOrderId
                            dateCreatedUtc
                            store {
                                id
                                subscriptionId
                            }
                            user {
                                id
                            }
                            lines {
                                __typename
                                id
                                dateCreatedUtc
                                fulfillerHubLineNumber
                                storeOrderLineNumber
                                storeOrderLineId
                                status {
                                    value
                                }
                                store {
                                    id
                                }
                            }
                        }
                    }
                }
            `, { orderId: orderId }
        ).pipe(map(r => r.order));
    }

    getStoreOrder(storeId: string, storeOrderId: string): Observable<Maybe<OrderUnion> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getStoreOrder($storeId: String!, $storeOrderId: String!) {
                    store(id: $storeId) {
                        order(storeOrderId: $storeOrderId) {
                            ... on Order {
                                __typename
                                id
                                originalCheckoutJson
                                rawData {
                                    value
                                    backupValue
                                }
                                storeOrderId
                                dateCreatedUtc
                                store {
                                    id
                                    subscriptionId
                                }
                                user {
                                    id
                                }
                                lines {
                                    __typename
                                    id
                                    dateCreatedUtc
                                    fulfillerHubLineNumber
                                    storeOrderLineNumber
                                    storeOrderLineId
                                    status {
                                        value
                                    }
                                }
                                properties {
                                    name
                                    value
                                }
                            }
                            ... on ArchivedOrder {
                                __typename
                                id
                                storeOrderId
                                store {
                                    id
                                    subscription {
                                        id
                                    }
                                }
                                lines {
                                    __typename
                                    id
                                    dateCreatedUtc
                                    fulfillerHubLineNumber
                                    storeOrderLineNumber
                                    storeOrderLineId
                                    status {
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            { storeId, storeOrderId }
        ).pipe(map(r => r.store.order));
    }

    getLine(lineId: string): Observable<Maybe<OrderLineUnion> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getStoreOrderLine($lineId: UUID!) {
                    line(id: $lineId) {
                        ... on OrderLine {
                            __typename
                            id
                            storeOrderLineNumber
                            storeOrderLineId
                            fulfillerHubLineNumber
                            storeProductId
                            quantity
                            dateCreatedUtc
                            price {
                                value
                                originalLinePrice
                                finalLinePrice
                                currency
                            }
                            properties {
                                name
                                value
                            }
                            fulfiller {
                                id
                            }
                            order {
                                __typename
                                id
                            }
                            status {
                                value
                                effectiveDateUtc
                                flags
                                details
                            }
                            shipping{
                                carrier
                                trackingNumber
                                trackingUrl
                            }
                            projectVersion {
                                id
                                sheetCount
                                additionalSheetCount
                                project {
                                    id
                                    module
                                    properties {
                                        name
                                        value
                                    }
                                    product {
                                        id
                                        label
                                    }
                                    theme {
                                        id
                                        label
                                    }
                                }
                            }
                            files {
                                id
                                url
                                productId
                                plu
                                quantity
                                errorMessage
                            }
                        }
                        ... on ArchivedOrderLine {
                            __typename
                            id
                            storeOrderLineNumber
                            storeOrderLineId
                            fulfillerHubLineNumber
                            storeProductId
                            quantity
                            dateCreatedUtc
                            price {
                                value
                                originalLinePrice
                                finalLinePrice
                                currency
                            }
                            fulfiller {
                                id
                            }
                            order {
                                id
                            }
                            status {
                                value
                                effectiveDateUtc
                                flags
                                details
                            }
                            projectVersion {
                                sheetCount
                                additionalSheetCount
                            }
                        }
                    }
                }
            `,
            { lineId }
        ).pipe(map(x => x.line));
    }

    getLineLogs(lineId: string): Observable<OrderLineLogUnion[] | ArchivedOrderLineLog[]> {
        return this.graphDotnetService.query(graphql`
                query getStoreOrderLine($lineId: UUID!) {
                    line(id: $lineId) {
                        ... on OrderLine {
                            logs(includeAppInsights: true) {
                                ... on OrderLineLog {
                                    __typename
                                    message
                                    source
                                    timestampUtc
                                    details
                                }
                                ... on OrderLineApplicationInsightsLog {
                                    __typename
                                    timestampUtc
                                    operationId
                                    duration
                                    success
                                    cloudRoleName
                                    cloudRoleInstance
                                    exceptions {
                                        itemId
                                        outerMessage
                                        severityLevel
                                        openQueryUrl
                                        details
                                    }
                                }
                            }
                        }
                        ... on ArchivedOrderLine {
                            logs {
                                __typename
                                message
                                value
                                timestampUtc
                            }
                        }
                    }
                }
            `,
            { lineId }
        ).pipe(map(x => x.line!.logs));
    }

    retryFailedOrderLines(orderId: string): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationRetryFailedOrderLinesArgs>(
            graphql`
                mutation retryFailedOrderLines($input: RetryFailedOrderLinesInput!) {
                    retryFailedOrderLines(input: $input)
                }
            `,
            {
                input: {
                    orderId
                }
            }
        ).pipe(map(result => result.retryFailedOrderLines));
    }

    forceRerenderOrderLine(lineId: string): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationForceRerenderOrderLineArgs>(
            graphql`
                mutation forceRerenderOrderLine($input: ForceRerenderOrderLineInput!) {
                    forceRerenderOrderLine(input: $input)
                }
            `,
            {
                input: {
                    lineId: lineId
                }
            }
        ).pipe(map(result => result.retryFailedOrderLines));
    }

    getRecentOrders(storeId: string): Observable<Order[]> {
        return this.graphDotnetService.query(graphql`
                query getRecentOrders($storeId: String!) {
                    store(id: $storeId) {
                        recentOrders {
                            __typename
                            id
                            storeOrderId
                            dateCreatedUtc
                            storeId
                            lines {
                                id
                                storeOrderLineNumber
                                storeOrderLineId
                                fulfillerHubLineNumber
                                dateCreatedUtc
                                status {
                                    value
                                    effectiveDateUtc
                                    flags
                                    details
                                }
                            }
                        }
                    }
                }
            `,
            { storeId: storeId }
        ).pipe(
            map(r => r.store!.recentOrders)
        );
    }

    setOrderLineStatus(
        lineId: string,
        status: OrderLineStatusValues,
        details: string | null,
        flags: OrderLineStatusFlags
    ): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationSetOrderLineStatusArgs>(
            graphql`
                mutation setOrderLineStatus(
                    $input: SetOrderLineStatusInput!
                ) {
                    setOrderLineStatus(input: $input)
                }
            `,
            { input: { lineId, status, details, flags } }
        ).pipe(map(result => result.setOrderLineStatus));
    }

    setOrderLineTrackingInformation(
        input: SetOrderLineTrackingInformationInput
    ): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationSetOrderLineTrackingInformationArgs>(
            graphql`
                mutation setOrderLineTrackingInformation(
                    $input: SetOrderLineTrackingInformationInput!
                ) {
                    setOrderLineTrackingInformation(input: $input)
                }
            `,
            { input: input }
        ).pipe(map(result => result.setOrderLineTrackingInformation));
    }

    createOrder(input: CreateOrderInput): Observable<Order> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateOrderArgs>(graphql`
                mutation createOrder($input: CreateOrderInput!) {
                    createOrder(input: $input) {
                        id
                        storeOrderId
                    }
                }
            `, { input }
        ).pipe(map(result => result.createOrder));
    }

    updateOrderRawData(input: UpdateOrderRawDataInput) {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateRawOrderDataArgs>(graphql`
                mutation updateRawOrderData($input: UpdateOrderRawDataInput!) {
                    updateRawOrderData(input: $input)
                }
            `, { input }
        ).pipe(map(result => result.updateRawOrderData));

    }
}
