<admin-page-simple-content
    id="recent-orders"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Recent Orders'"
    [icon]="'history'"
>
    <div class="content">
        <table mat-table [dataSource]="orders" multiTemplateDataRows="" class="mat-elevation-z4">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Store Order Id</th>
                <td mat-cell *matCellDef="let order">
                    <code>{{ order$(order).storeOrderId }}</code>
                </td>
            </ng-container>
            <ng-container matColumnDef="dateCreatedUtc">
                <th mat-header-cell *matHeaderCellDef>Date Created</th>
                <td mat-cell *matCellDef="let order">
                    {{ order$(order).dateCreatedUtc | date: "mediumDate" }} ({{
                        order$(order).dateCreatedUtc | fromNow
                    }})
                </td>
            </ng-container>
            <ng-container matColumnDef="lines">
                <td mat-cell *matCellDef="let order" [attr.colspan]="columnsToDisplay.length" class="lines">
                    <div>
                        @for (line of order$(order).lines; track line) {
                            <div
                                appInteract
                                tabindex="0"
                                class="line"
                                mat-ripple
                                (interact)="router.navigateByUrl(
                                    '/stores/' +
                                    order$(order).storeId +
                                    '/orders/' +
                                    order$(order).storeOrderId +
                                    '/lines/' +
                                    line.storeOrderLineNumber)"
                            >
                                <div>
                                    <code>{{ line.id }}</code> (LineNumber: {{ line.storeOrderLineNumber }})
                                </div>
                                <div>
                                    <admin-line-status
                                        [status]="line.status.value"
                                        [flags]="line.status.flags"
                                    ></admin-line-status>
                                    <span class="last-update-date">
                                        &nbsp;{{ line.status.effectiveDateUtc | fromNow }}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let order; columns: columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: ['lines']" class="example-detail-row"></tr>
        </table>
    </div>
</admin-page-simple-content>
