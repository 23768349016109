import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appInteract]'
})
export class InteractDirective {
    @Output() interact = new EventEmitter<Event>();

    constructor(private el: ElementRef) {
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        this.interact.emit(event);
    }

    @HostListener('keydown.enter', ['$event'])
    onEnter(event: KeyboardEvent): void {
        this.interact.emit(event);
    }

    @HostListener('keydown.space', ['$event'])
    onSpace(event: KeyboardEvent): void {
        event.preventDefault(); // Prevent page scrolling
        this.interact.emit(event);
    }
}
