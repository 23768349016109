import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { GlobalPermission, Maybe, Project, ProjectVersion, StorePermission } from 'app/shared/graph';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { AdminComponentBase, PageSimpleContentComponent } from '../../../layout/content';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { ViewContextService } from '../../../navigation/view-context.service';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatTab, MatTabContent, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { MatButton, MatIconAnchor } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ProjectsService } from './projects.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDownloadingDialogComponent } from './project-downloading-dialog.component';
import { CodeEditor } from '@acrodata/code-editor';
import { languages } from '@codemirror/language-data';

@Component({
    templateUrl: './project-page.component.html',
    styleUrls: ['./project-page.component.scss'],
    imports: [PageSimpleContentComponent, ErrorComponent, MatTabGroup, MatTab, MatButton, RouterLink, MatIcon, MatIconAnchor, MatMenuTrigger, MatMenu, MatMenuItem, CopyToClipboardComponent, MatTabLabel, MatTabContent, MatProgressSpinner, FormsModule, DatePipe, CodeEditor]
})
export class ProjectPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly authService = inject(AuthorizationService);
    private readonly projectsService = inject(ProjectsService);
    private readonly dynamicScriptLoader = inject(DynamicScriptLoaderService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly matDialog = inject(MatDialog);
    private readonly viewContextService = inject(ViewContextService);

    projectId: string;
    projectVersionId: number | null;

    project?: Maybe<Project>;
    projectVersion?: Maybe<ProjectVersion>;
    authContext?: AuthorizationContext;

    loadHubMinJs: Promise<any>;

    constructor() {
        super();
        this.projectId = this.route.snapshot.params.projectId;
        this.projectVersionId = +this.route.snapshot.params.projectVersionId || null;
        this.loadHubMinJs = this.dynamicScriptLoader.load('mediaclip-hub');
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            let projectVersionId = +params.projectVersionId || null;
            if (projectVersionId != this.projectVersionId) {
                this.selectProjectVersion(projectVersionId);
            }
        });

        this.loadProject();
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    selectProjectVersion(projectVersionId?: number | null) {
        if (!this.project) {
            return;
        }

        this.projectVersionId = projectVersionId || null;
        if (projectVersionId) {
            this.projectsService.getProjectVersion(this.projectId, projectVersionId).subscribe({
                next: (version) => {
                    this.projectVersion = version;
                },
                error: (err) => {
                    this.setLoadingError(err);
                }
            });
        }
    }

    getOrientation(width: number, height: number): string {
        if (width === height) {
            return 'square';
        } else if (width > height) {
            return 'landscape';
        } else {
            return 'portrait';
        }
    }

    getVersionPosition(project: Project, projectVersion: ProjectVersion) {
        return project.versions.findIndex((v) => v.id === projectVersion.id) + 1;
    }

    saveXml(projectVersionId: number, xml: string) {
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(xml, 'application/xml');
        if (doc.querySelector('parsererror')) {
            alert('Errors in the XML document. Please fix any errors and try again.');
            return;
        }
        this.subscribeWithGenericSavinErrorHandling(this.projectsService.updateProjectContent({
            projectVersionId: projectVersionId,
            projectXml: xml
        }));
    }

    download(projectVersionId: number) {
        this.subscribeWithGenericSavinErrorHandling(
            this.projectsService.downloadProjectVersion({
                projectVersionId: projectVersionId
            }),
            result => {
                this.matDialog.open(ProjectDownloadingDialogComponent, {
                    data: {
                        downloadUrl: result.downloadUrl
                    }
                });
            }
        );
    }

    delete(projectId: string) {
        this.confirmDialogService.confirm(
            'Delete project',
            'Do you really want to delete this project?',
            undefined,
            {
                yes: {
                    label: 'Delete',
                    warn: true
                },
                no: {
                    label: 'Cancel'
                }
            }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.projectsService.deleteProject({ id: projectId }), () => {
                this.loadProject();
            });
        });
    }

    canManageProject(project: Project) {
        return this.authContext?.hasStorePermissions(project.store.id, project.store.subscriptionId, StorePermission.ManageProjects);
    }

    canDownloadProject(project: Project) {
        return this.authContext?.hasGlobalPermissions(GlobalPermission.DownloadProjects);
    }

    canReadStoreUsers(project: Project) {
        return this.authContext?.hasStorePermissions(project.store.id, project.store.subscriptionId, StorePermission.ReadStoreUsers);
    }

    canManagePhotos(project: Project) {
        return this.authContext?.hasStorePermissions(project.store.id, project.store.subscriptionId, StorePermission.ManagePhotos);
    }

    private loadProject() {
        this.subscribeWithGenericLoadingErrorHandling(this.projectsService.getProject(this.projectId), (project) => {
            this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
            this.project = project;
            if (project) {
                this.viewContextService.selectStore(project.storeId);
                if (!this.projectVersionId) {
                    this.selectProjectVersion(project.currentVersionId);
                } else {
                    this.selectProjectVersion(this.projectVersionId);
                }
            }
        });
    }

    invalidateCachedPhoto(projectVersion: ProjectVersion) {
        this.confirmDialogService.confirm(
            'Invalidate photos cache',
            'Do you want to invalidate cache of all external photos in this project?',
            'If the user token to the photo source is not valid anymore this project will not be able to be rendered.'
        ).subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.projectsService.invalidateProjectStorageExternalPhotosCache(projectVersion.id));
            }
        );
    }

    protected readonly languages = languages;
}
