import { GlobalPermission } from 'app/shared/graph';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { DesignerBranchService } from './designer-branch.service';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { AdminComponentBase, PageSimpleContentComponent, PageStatus } from '../../../layout/content';
import { SaveButtonComponent } from '../../../shared/components/save-button.component';
import { MatSnackBar } from '@angular/material/snack-bar';

enum Scope {
    global = 'global',
    subscription = 'subscription'
}

@Component({
    selector: 'admin-manage-designer-release-channel-version',
    templateUrl: './manage-designer-release-channel-version.component.html',
    styleUrls: ['./manage-designer-release-channel-version.component.scss'],
    imports: [PageSimpleContentComponent, MatCard, MatCardHeader, MatCardTitle, MatCardContent, RouterLink, MatCardActions, SaveButtonComponent]
})
export class ManageDesignerReleaseChannelVersionPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly authorizationService = inject(AuthorizationService);
    private readonly designerBranchService = inject(DesignerBranchService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly matSnackBar = inject(MatSnackBar);


    private authorizationContext: AuthorizationContext;

    subscriptionId?: string;
    scope: Scope;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authorizationContext = authContext;
        });
        this.setPageStatus(PageStatus.loaded);
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    canManage(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageDesignerBranch);
    }

    updateToDeprecated() {
        this.confirmDialogService.confirm('Pre-Deployment Stable', 'Are you sure you want to execute this action?', undefined, { yes: { label: 'Yes' } })
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.designerBranchService.switchNonAutomaticUpgradeStoresToDeprecatedStableChannel(), () => this.matSnackBar.open('Stores switched to Deprecated Stable branch'));
            });
    }

    updateToStable() {
        this.confirmDialogService.confirm('Post-deployment Stable (8 weeks later)', 'Are you sure you want to execute this action?', undefined, { yes: { label: 'Yes' } })
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.designerBranchService.switchStoresOnDeprecatedStableChannelToStableChannel(), () => this.matSnackBar.open('Stores switched to Stable branch'));
            });
    }
}

