import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { PageStatus } from '../../../layout/content';
import { Store, StorePermission, StoreStatus, Subscription, SubscriptionPermission } from 'app/shared/graph';
import { SubscriptionsService } from './subscriptions.service';
import { AuthorizationContext } from 'app/shared/authentication';
import { CreateStoreDialogComponent, CreateStoreDialogResult } from './create-store-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { ErrorComponent } from 'app/shared/components/error.component';
import { RouterLink } from '@angular/router';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatTooltip } from '@angular/material/tooltip';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'admin-subscription-stores-component',
    templateUrl: './subscription-stores-component.component.html',
    styleUrls: ['./subscription-stores-component.component.scss'],
    imports: [MatIconButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, MatFormField, MatPrefix, MatInput, FormsModule, MatChipRemove, MatButton, ErrorComponent, MatTable, MatHeaderRow, MatRow, MatColumnDef, MatHeaderCell, MatCell, RouterLink, CopyToClipboardComponent, MatTooltip, DecimalPipe, MatRowDef, MatHeaderRowDef, MatHeaderCellDef, MatCellDef, MatChipListbox, MatChipOption]
})
export class SubscriptionStoresComponentComponent implements OnInit {
    private readonly dialog = inject(MatDialog);
    private readonly subscriptionsService = inject(SubscriptionsService);


    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    displayedStores: Store[] = [];
    filter: {
        name?: string;
        showActiveOnly: boolean,
    } = {
        showActiveOnly: true
    };

    @Input()
    subscription: Subscription;
    @Input()
    auth: AuthorizationContext;

    @ViewChild('storesTable', { static: false })
    storesTable: MatTable<any>;

    ngOnInit(): void {
        const savedFilter = localStorage.getItem('subscription-stores-page-filter');
        if (savedFilter) {
            this.filter = { ...this.filter, ...JSON.parse(savedFilter) as any };
        }
        this.updateDisplayedSubscription();
    }

    openAddStoreDialog() {
        this.dialog.open<CreateStoreDialogComponent, any, CreateStoreDialogResult>(CreateStoreDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.pageStatus = PageStatus.saving;
            this.subscriptionsService.createStore({
                ...result,
                subscriptionId: this.subscription.id
            }).subscribe({
                next: (createdStore) => {
                    this.subscription?.stores?.push(createdStore);
                    this.pageStatus = PageStatus.loaded;
                    this.updateDisplayedSubscription();
                },
                error: err => {
                    this.pageError = err;
                    this.pageStatus = PageStatus.saveError;
                }
            });
        });

    }

    updateDisplayedSubscription() {
        if (this.subscription.stores) {
            this.displayedStores = this.subscription.stores
                .filter(s => this.isStoreVisible(s));
            this.storesTable?.renderRows();
        }
    }

    toggleShowActiveOnly() {
        this.filter.showActiveOnly = !this.filter.showActiveOnly;
        this.updateFilter();
    }

    public updateFilter() {
        localStorage.setItem('subscription-stores-page-filter', JSON.stringify({ ...this.filter, name: undefined }));
        this.updateDisplayedSubscription();
    }

    private isStoreVisible(s: Store) {
        if (this.filter.showActiveOnly) {
            if (s.status !== StoreStatus.Active) {
                return false;
            }
        }
        if (this.filter.name) {
            if (!s.id.toLowerCase().includes(this.filter.name.toLowerCase())
                && !s.label?.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;

    }

    canAddStore(): boolean {
        return this.auth.hasSubscriptionPermissions(this.subscription.id, SubscriptionPermission.CreateStore);
    }

    canManageStoreMetadata(storeId: string): boolean {
        return this.auth.hasStorePermissions(storeId, this.subscription.id, StorePermission.ManageStoreMetadata);
    }

}
