<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Manage admin user'"
    [icon]="'save'"
>
    @if (user) {
        <div class="content">
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
            <div class="header">
                <img [alt]="'Profile picture of ' + user.user.email" [src]="user.user.picture" class="user-avatar"/>
                <h2 adminCopyToClipboard>{{ user.user.email }}</h2>
                <mat-icon tabindex="0" *ngIf="user.user.isMfaEnabled"
                          [matTooltip]="'Multi-factor Authentication enabled'">security
                </mat-icon>
                <mat-icon tabindex="0" *ngIf="!user.user.isMfaEnabled" style="color: orange"
                          [matTooltip]="'Multi-factor Authentication disabled'">warning
                </mat-icon>
            </div>
            <section class="info">
                <h3>Info</h3>
                <ul>
                    <li>
                        Auth0 Id: <code adminCopyToClipboard>{{ user.user.id }}</code>
                    </li>
                    @if (user.user.lastLoginDate) {
                        <li>
                            Last login:
                            {{ user.user.lastLoginDate | date: "full" }}
                            <span>({{ user.user.lastLoginDate | fromNow }})</span>
                        </li>
                    }
                </ul>
            </section>
            <section class="global-roles">
                <h3>Global Roles</h3>
                <div class="roles">
                    <mat-chip-listbox aria-label="User roles">
                        @for (role of user.globalRoles; track role) {
                            <mat-chip-option selectable="false" [removable]="canManageUser()"
                                             (removed)="removeGlobalRole(user, role)">
                                {{ role }}
                                @if (canManageUser()) {
                                    <button
                                        matChipRemove
                                        [disabled]="pageStatus === 'saving'"
                                        [attr.aria-label]="'Remove role: ' + role"
                                    >
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                }
                            </mat-chip-option>
                        }
                    </mat-chip-listbox>
                    @if (canManageUser()) {
                        <button mat-icon-button (click)="openAddGlobalRole(user)" aria-label="Add global role">
                            <mat-icon>add</mat-icon>
                        </button>
                    }
                </div>
            </section>
            <section class="downloads">
                <h3>Authorized downloads</h3>
                <div class="download-list">
                    <mat-chip-listbox aria-label="User allowed downloads">
                        @for (download of user.downloads; track download) {
                            <mat-chip-option selectable="false" [removable]="canManageUser()"
                                             (removed)="removeDownload(user, download)">
                                {{ download }}
                                @if (canManageUser()) {
                                    <button
                                        matChipRemove
                                        [disabled]="pageStatus === 'saving'"
                                        [attr.aria-label]="'Remove download: ' + download"
                                    >
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                }
                            </mat-chip-option>
                        }
                    </mat-chip-listbox>
                    <form #form="ngForm" (ngSubmit)="addDownload(downloadValue)">
                        <mat-form-field class="dense-2">
                            <mat-label>New download</mat-label>
                            <input
                                matInput
                                name="downloadValue"
                                [(ngModel)]="downloadValue"
                                [matAutocomplete]="auto"
                                required
                            />
                        </mat-form-field>
                        <mat-autocomplete #auto="matAutocomplete">
                            @for (download of filterDownloads(availableDownloads); track download) {
                                <mat-option [value]="download.path">
                                    {{ download.path }}
                                </mat-option>
                            }
                        </mat-autocomplete>
                        <button mat-stroked-button type="submit" [disabled]="form.invalid || pageStatus === 'saving'">
                            <mat-icon>add</mat-icon>
                            Add
                        </button>
                    </form>
                </div>
            </section>
            <section class="subscriptions-roles" *ngIf="user.subscriptionsRoles?.length > 0">
                <h3>Subscriptions Roles</h3>
                <ul>
                    @for (subscriptionRoles of user.subscriptionsRoles; track subscriptionRoles) {
                        <li>
                            Subscription: <a
                            tabindex="0"
                            [routerLink]="['/subscriptions', subscriptionRoles.subscriptionId, 'authorizedUsers']">{{ subscriptionRoles.subscriptionId }}</a>
                            <mat-chip-set>
                                @for (role of subscriptionRoles.roles; track subscriptionRoles) {
                                    <mat-chip>
                                        {{ role }}
                                    </mat-chip>
                                }
                            </mat-chip-set>
                        </li>
                    }
                </ul>
            </section>
            <section class="stores-roles" *ngIf="user.storesRoles?.length > 0">
                <h3>Stores Roles</h3>
                <ul>
                    @for (storeRoles of user.storesRoles; track storeRoles) {
                        <li>
                            Store: <a
                            tabindex="0"
                            [routerLink]="['/stores', storeRoles.storeId, 'settings', 'authorized-users']">{{ storeRoles.storeId }}</a>
                            <mat-chip-set>
                                @for (role of storeRoles.roles; track storeRoles) {
                                    <mat-chip>
                                        {{ role }}
                                    </mat-chip>
                                }
                            </mat-chip-set>
                        </li>
                    }
                </ul>
            </section>
            <section class="libraries-roles" *ngIf="user.librariesRoles?.length > 0">
                <h3>Libraries Roles</h3>
                <ul>
                    @for (libraryRoles of user.librariesRoles; track libraryRoles) {
                        <li>
                            Library: <a
                            tabindex="0"
                            [routerLink]="['/libraries', libraryRoles.libraryOwner, 'authorizedUsers']">{{ libraryRoles.libraryOwner }}</a>
                            <mat-chip-set>
                                @for (role of libraryRoles.roles; track libraryRoles) {
                                    <mat-chip>
                                        {{ role }}
                                    </mat-chip>
                                }
                            </mat-chip-set>
                        </li>
                    }
                </ul>
            </section>
            <section class="fulfillers-roles" *ngIf="user?.fulfillersRoles?.length > 0">
                <h3>Fulfillers Roles</h3>
                <ul>
                    @for (fulfillerRoles of user.fulfillersRoles; track fulfillerRoles) {
                        <li>
                            Fulfiller: <a tabindex="0"
                                          [routerLink]="['/fulfillers', fulfillerRoles.fulfillerId, 'authorizedUsers']">{{ fulfillerRoles.fulfillerId }}</a>
                            <mat-chip-set>
                                @for (role of fulfillerRoles.roles; track fulfillerRoles) {
                                    <mat-chip>
                                        {{ role }}
                                    </mat-chip>
                                }
                            </mat-chip-set>
                        </li>
                    }
                </ul>
            </section>
            <section class="user-actions">
                <h3>Actions</h3>
                <div class="actions">
                    <button mat-raised-button color="warn" [disabled]="hasAnyRole(user) || pageStatus === 'saving'"
                            (click)="deleteUser(user)">
                        Delete User
                    </button>
                    @if (hasAnyRole(user)) {
                        <span tabindex="0"
                              [matTooltip]="'You need to remove all roles before deleting this user'">
                            <mat-icon color="warn">warning</mat-icon>
                        </span>
                    }
                </div>
            </section>
        </div>
    }
</admin-page-simple-content>
