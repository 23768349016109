import { Component, Input } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ErrorComponent } from '../../shared/components/error.component';
import { PageStatus } from './page-status';

@Component({
    selector: 'admin-page-simple-content',
    templateUrl: './page-simple-content.component.html',
    styleUrls: ['./page-simple-content.component.scss'],
    animations: fuseAnimations,
    imports: [MatIcon, MatProgressSpinner, ErrorComponent]
})
export class PageSimpleContentComponent {
    @Input() title: string;
    @Input() icon: string;
    @Input() pageStatus: PageStatus = PageStatus.loading;
    @Input() pageError?: Error;

    constructor() {}
}
